import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import FormatData from '../../other/FormatData';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';

const CategoryAddModal = (props) => {
    const [topic, setTopic] = useState({
        slug: '',
        nameVi: '',
        contentVi: '',
        errors: [],
    });

    const handleClose = () => props.onHide();
    const [selectedImage, setSelectedImage] = useState(null);
    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        topic[field] = value;
        delete (topic.errors[field]);

        if (field === 'nameVi') {
            let convertToSlug = FormatData.convertToSlug(value);

            delete (topic.errors?.slug);
            topic.slug = convertToSlug;
        }

        setTopic({ ...topic });
    }

    const handleSelectFile = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setSelectedImage(base64String);
            };
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setSelectedImage(base64String);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            slug: topic.slug,
            name: topic.nameVi.trim(),
            image: selectedImage,
        };

        await axios.post(`/category/create`, data).then((res) => {
            if (res.data.errCode === 1) {
                let topicData = { ...topic };
                topicData.errors = res.data.errMessage;
                setTopic(topicData);

                toast('Thêm danh mục thất bại.', { type: 'error' });
            } else {

                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();

                toast(res.data.errMessage, { type: 'success' });
            }
        }).catch((error) => {
        });
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Thêm danh mục</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)} name="nameVi"
                             value={topic.nameVi} className={topic.errors?.name ? 'is-invalid' : ''} placeholder="Tên danh mục" required />
                            <Form.Label>Tên danh mục<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{topic.errors?.name ? topic.errors?.name : ''}</span>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" name="slug" value={topic.slug} className={topic.errors?.slug ? 'is-invalid' : ''} placeholder="Slug" readOnly />
                            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
                            <span className='err-input'>{topic.errors?.slug ? topic.errors?.slug : ''}</span>
                        </Form.Floating>

                        <div>
                            <label className="btn btn-primary mb-2" style={{ fontSize: 12, cursor: "pointer" }}
                                htmlFor="banner-top"> Thêm banner danh mục</label>
                            <input
                                type="file"
                                id="banner-top"
                                className="d-none"
                                accept='image/*'

                                multiple={false}
                                onChange={(e) => handleSelectFile(e)}

                            />

                        </div>
                        <Row>
                            <Col>
                                {selectedImage && <Button onClick={() => setSelectedImage(null)} variant='outline-secondary' style={{ float: 'right', zIndex: '1', position: 'relative', marginBottom: '-35px' }}>X</Button>}
                                {selectedImage &&
                                    <img src={`data:image/jpeg;base64,${selectedImage}`} alt="Hình ảnh tải lên" className='banner-admin' />}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default CategoryAddModal;