import React from 'react';
import DocumentMeta from 'react-document-meta';
import { useLocation } from 'react-router-dom';

const HeadMeta = (props) => {
    const isEmpty = Object.keys(props).length === 0;

    const reactUrl = process.env.REACT_APP_FRONTEND_URL + useLocation().pathname;
    const title = !isEmpty ? props.title : 'VBI';
    const description = !isEmpty ? props.description : "VBI - Bảo hiểm VietinBank.";
    const keywords = !isEmpty ? props.keywords : "VBI,Bảo hiểm VietinBank"
    // const og_image = !isEmpty ? props.og_image : '/favicon.ico';
    // const fb_app_id = !isEmpty ? props.fb_app_id : '';

    const meta = {
        robots: "index, follow",
        title: title,
        description: description,
        canonical: reactUrl,
        meta: {
            title: title,
            copyright: 'Katec',
            author: 'Katec',
            name: {
                keywords: keywords,
                // ['geo.region']: "VN",
                // ['geo.placename']: "Cần Thơ",
                // ['geo.position']: "10.0410606; 105.7513588",
                // ['geo.ICBM']: "10.0410606, 105.7513588",
            },
            // property: {
            //     ['og:title']: title,
            //     ['og:site_name']: '',
            //     ['og:url']: reactUrl,
            //     ['og:type']: 'article',
            //     ['og:image']: og_image,
            //     ['og:description']: description,
            //     ['fb:app_id']: fb_app_id,
            // }
        }
    };

    return (<DocumentMeta {...meta} />)
}

export default HeadMeta;