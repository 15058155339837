import React, { useState } from "react";
// import MyCoverflow from "./MyCoverflow";
import { Carousel, Row, Col } from "react-bootstrap";
import img1 from '../../../assets/user/images/feedback/img_6.svg'
import img2 from '../../../assets/user/images/feedback/img_7.svg'

const Feedback = () => {

    const data = [

        {
            id: 1, userName: "Nguyễn Thị Ngọc Anh", address: ' Khách hàng tại Nam Định'
            , cmt: `“Tôi cảm thấy rất hài lòng và được đáp ứng nhanh chóng về các dịch vụ bồi thường bảo hiểm sức khỏe. Các bạn nhân viên chăm sóc sát sao khách hàng nhằm đảm bảo quyền lợi tốt nhất. Cảm ơn VBI!”`
        },
        {
            id: 2, userName: "Đỗ Duy Tuấn",
            address: 'Khách hàng tại Hải Phòng',
            cmt: `“VBI xử lý hồ sơ nhanh, chụp ảnh xong là có email xác nhận, chi phí bồi thường được giải thích rõ ràng bởi tư vấn viên hoặc file gửi qua email. Việc áp dụng công nghệ 4.0 khiến quy trình xử lý rất nhanh gọn, chất lượng dịch vụ trên cả tuyệt vời.”`
        },
        { id: 3, userName: "Lại Mai Hoa", address: "Khách hàng tại Hà Nội", cmt: `“Thủ tục giải quyết bồi thường của VBI rất nhanh chóng và tiện lợi, nhân viên công ty thân thiện và hỗ trợ nhiệt tình.”` },
        {
            id: 4, userName: "Nguyễn Diệu Linh", address: ' Khách hàng tại Hà Nội'
            , cmt: `“Bảo hiểm VietinBank – VBI có mức phí bảo hiểm rất tốt, hỗ trợ bồi thường và tra cứu hồ sơ nhanh chóng. Mọi tính năng đều rất thuận tiện, dễ dùng và dễ kiểm tra. Tôi đánh giá 5 sao!”`
        },
        {
            id: 5, userName: "Nguyễn Thu Thủy",
            address: 'Khách hàng tại Hà Nội',
            cmt: `“Gia đình mình có tham gia bảo hiểm sức khỏe của VBI và rất hài lòng với dịch vụ, bồi thường nhanh ngoài mong đợi, nhân viên hỗ trợ nhiệt tình. Mình sẽ tiếp tục sử dụng VBI vào những năm tiếp theo. Cảm ơn VBI!”` },
        { id: 6, userName: "Lương Hoàng Vũ", address: "Khách hàng tại Hà Nội", cmt: `“Con gái tôi có tham gia gói bảo hiểm của Bảo hiểm VietinBank nhưng không may con tôi gặp tai nạn qua đời đột ngột. Công ty Bảo hiểm VietinBank Chi nhánh Hải Phòng đã chi trả số tiền bồi thường 100 triệu cho gia đình. Tôi xin đại diện cho gia đình cảm ơn Công ty Bảo hiểm VietinBank.”` },


    ]
    const itemsPerSlide = 3; // Số phần tử trên mỗi slide
    const totalSlides = Math.ceil(data.length / itemsPerSlide);

    const generateSlides = () => {
        const slides = [];
        for (let slideIndex = 0; slideIndex < totalSlides; slideIndex++) {
            const startIndex = slideIndex * itemsPerSlide;
            const endIndex = startIndex + itemsPerSlide;
            const slideData = data.slice(startIndex, endIndex);

            const slideCols = slideData.map(item => (
                <Col key={item.id} md={4} className="
             
           box1
                 " 
                 >
                      {/* //  pb-2 pt-3 */}
                    {/* <div className="testimonial"> */}
                        <Row>
                            <Col md={3} xs={3}>
                                <img src= {item.id%2 ? img1:img2} alt="icon" />
                            </Col>
                            <Col md={9} xs={9}>
                                <p className="user-name">{item.userName}</p>
                                <p className="address">{item.address}</p>
                            </Col>
                        </Row>

                        <p className="comment">{item.cmt}</p>
                    {/* </div> */}
                </Col>
            ));

            slides.push(
                <Carousel.Item key={slideIndex}>
                    <Row
                    
                    >{slideCols}</Row>
                </Carousel.Item>
            );
        }
        return slides;
    };

    return (
        <Carousel interval={3000} wrap={true} className='bg-white'>
            {/* <Row> */}
            {generateSlides()}

            {/* </Row> */}
        </Carousel>
    );

}
export default Feedback;
