import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormatData from '../../other/FormatData';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import TextEditor from '../TextEditor';
import Select from "react-select";

const ProductEditModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        getTopic();
    }, [navigate]);

    const [slug, setSlug] = useState('');
    const [nameVi, setNameVi] = useState('');
    const [contentVi, setContentVi] = useState('');
    const [errors, setErrors] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage1, setSelectedImage1] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const id = props.idEdit || '';
    // const [contentVi, setContentVi] = useState('');
    const [contentInf, setContentInf] = useState('');
    const [categoryChoice, setCategoryChoice] = useState(props.categories[0])
    const [contentObj, setContentObj] = useState('');
    const [contentTra, setContentTra] = useState('');
    const [contentDoc, setContentDoc] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImagepost, setSelectedImagepost] = useState(null);
    const [selectedImage2post, setSelectedImage2post] = useState(null);


    const getTopic = async () => {
        if (id !== '') {
            setIsLoading(true);
            await axios.get(`/product/${id}`).then((res) => {
                if (res.data.errCode === 0) {
                    let topicData = res.data.product;
                    setSlug(topicData.slug);
                    setNameVi(topicData.name);
                    setContentVi(topicData.description);
                    setContentDoc(topicData.document)
                    setContentInf(topicData.productInfo)
                    setContentObj(topicData.insuranceObject)
                    setContentTra(topicData.indemnityGuide)
                    setIsLoading(false);
                    setSelectedImage(topicData.banner1)
                    setSelectedImage2(topicData.banner2)
                    setCategoryChoice(props?.categories?.filter(e => e.value == topicData.categoryId)[0])
                } else {
                    toast(res.data.message, { type: 'error' });
                    setIsLoading(false);
                }
            }).catch((error) => {
                // toast(error.message, { type: 'error' });
                setIsLoading(false);
            });
        }
    }

    const handleClose = () => props.onHide();

    const handleSelectFile = (type, e) => {
        if (e.target.files[0]) {
            if (type == 0) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage(`data:image/jpeg;base64,${base64String}`);
                    setSelectedImagepost(base64String)
                };
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage(`data:image/jpeg;base64,${base64String}`);
                    setSelectedImagepost(base64String)
                };
                reader.readAsDataURL(e.target.files[0]);
            }
            if (type == 1) {

                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage1(base64String);

                };
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage1(base64String);
                };
                reader.readAsDataURL(e.target.files[0]);
            }
            if (type == 2) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage2(`data:image/jpeg;base64,${base64String}`);
                    setSelectedImage2post(base64String)
                };
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];

                    setSelectedImage2(`data:image/jpeg;base64,${base64String}`);
                    setSelectedImage2post(base64String)
                };
                reader.readAsDataURL(e.target.files[0]);
            }
        }



    }
    const handleInputNameVi = (e) => {
        let value = e.target.value;
        let convertToSlug = FormatData.convertToSlug(value);

        let dataErrors = { ...errors };
        delete (dataErrors.slug);
        delete (dataErrors.nameVi);

        setNameVi(value);
        setSlug(convertToSlug);
        setErrors(errors);

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        await swal({
            title: "Bạn muốn cập nhật sản phẩm?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then(async (ok) => {
            if (ok) {
                const data = {
                    categoryId: categoryChoice.value,
                    name: nameVi,
                    description: contentVi,
                    slug: slug,
                    productInfo: contentInf,
                    insuranceObject: contentObj,
                    indemnityGuide: contentTra,
                    document: contentDoc,
                    image1: selectedImagepost,
                    image2: selectedImage2post
                };
                await axios.put(`/product/update/${id}`, data).then((res) => {
                    if (res.data.errCode === 1) {
                        setErrors(res.data.errMessage);
                        toast('Cập nhật sản phẩm thất bại.', { type: 'error' });
                    } else {
                        const { onSubmitSuccess } = { ...props };
                        onSubmitSuccess();

                        toast(res.data.errMessage, { type: 'success' });
                    }
                }).catch((error) => {
                    toast('Lỗi', { type: 'error' });
                })
            }
        })
    }

    return (
        <>
            <Modal size='xl' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết sản phẩm</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Modal.Body>
                            <div>
                                <label className="btn btn-primary mb-2" style={{ fontSize: 12, cursor: "pointer" }} htmlFor="banner-top"> Thêm banner-top</label>
                                <input
                                    type="file"
                                    id="banner-top"
                                    className="d-none"
                                    accept='image/*'

                                    multiple={false}
                                    onChange={(e) => handleSelectFile(0, e)}

                                />

                            </div>
                            <Row>
                                <Col>
                                    {selectedImagepost ?
                                        <Image className='mb-3 banner-admin' src={`data:image/jpeg;base64,${selectedImagepost}`} thumbnail height={'60px'}  />
                                        : <Image className='mb-3 banner-admin' src={selectedImage}
                                            thumbnail height={'60px'} />
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mb-3 mt-3'>
                                    <Form.Label>Danh mục sản phẩm</Form.Label>
                                    < Select
                                        options={props.categories}
                                        // defaultValue={props.categories[0]}
                                        value={categoryChoice}
                                        name='category'
                                        onChange={(choice) => {
                                            setCategoryChoice(choice)

                                        }}


                                    >
                                    </Select >
                                </Col>
                            </Row>

                            <Form.Floating className="mb-3">
                                <Form.Control type="text"
                                    onChange={(e) => handleInputNameVi(e)}
                                    name="nameVi"
                                    value={nameVi}

                                    placeholder="Tên danh mục" required />
                                <Form.Label>Tên sản phẩm<i className="text-danger">*</i></Form.Label>

                            </Form.Floating>

                            <Form.Floating className="mb-3">
                                <Form.Control type="text" name="slug"
                                    value={slug}
                                    placeholder="Slug" readOnly />
                                <Form.Label>Slug<i className="text-danger">*</i></Form.Label>

                            </Form.Floating>

                            <Form.Label>Mô tả</Form.Label>

                            <TextEditor setContents={contentVi} onChange={(value) => setContentVi(value)} placeholder="Mô tả" />

                            <Form.Label>Thông tin sản phẩm</Form.Label>

                            <TextEditor setContents={contentInf} onChange={(value) => setContentInf(value)} placeholder="Thông tin sản phẩm" />

                            <Form.Label>Đối tượng được bảo hiểm</Form.Label>

                            <TextEditor setContents={contentObj} onChange={(value) => setContentObj(value)} placeholder="Đối tượng được bảo hiểm" />

                            <Form.Label>Hướng dẫn bồi thường</Form.Label>

                            <TextEditor setContents={contentTra} onChange={(value) => setContentTra(value)} placeholder="Hướng dẫn bồi thường" />

                            <Form.Label>Tài liệu</Form.Label>

                            <TextEditor setContents={contentDoc} onChange={(value) => setContentDoc(value)} placeholder="Tài liệu" />

                            <div>
                                <label className="btn btn-primary mt-2" style={{ fontSize: 12, cursor: "pointer" }} htmlFor="banner-end"> Thêm banner-end</label>
                                <input
                                    type="file"
                                    id="banner-end"
                                    className="d-none"
                                    accept='image/*'

                                    multiple={false}
                                    onChange={(e) => handleSelectFile(2, e)}

                                />

                            </div>
                            <Row>
                                <Col>
                                    {selectedImage2post ?
                                        <Image className='mb-3 banner-admin' src={`data:image/jpeg;base64,${selectedImage2post}`} thumbnail height={'60px'} />
                                        : <Image className='mb-3 banner-admin' src={selectedImage2}
                                            thumbnail height={'60px'} />
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Cập nhật
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ProductEditModal;