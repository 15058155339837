import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Error404 = () => {
    return (
        <div id="layoutError_content">
            <main>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="text-center mt-4">
                                <h1>Error 404 </h1>

                                <p className="lead">Không tìm thấy URL được yêu cầu.</p>

                                <Link to="/" className='btn btn-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} />Quay lại
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Error404;