import React from "react";
import DataTable from "react-data-table-component";

function DataTables(props) {
	const customStyles = {
		head: {
			style: {
				top: "-1px",
			},
		},
		headRow: {
			style: {
				// border: "none",
			},
		},
		headCells: {
			style: {
				color: "#202124",
				fontSize: "14px",
				zIndex: "unset",
			},
		},
		rows: {
			highlightOnHoverStyle: {
				backgroundColor: "rgb(230, 244, 244)",
				borderBottomColor: "#FFFFFF",
				// borderRadius: "25px",
				outline: "1px solid #FFFFFF",
			},
		},
		pagination: {
			style: {
				border: "none",
			},
		},
	};

	const handleRowSelected = (state) => {
		if (props.selectedRows) {
			return props.selectedRows(state.selectedRows);
		}
	};

	return (
		<DataTable
			customStyles={customStyles}
			highlightOnHover
			pointerOnHover
			onSelectedRowsChange={handleRowSelected}
			fixedHeader={true}
			fixedHeaderScrollHeight={"500px"}
			pagination
			paginationPerPage={30}
			paginationRowsPerPageOptions={[30, 50, 100, 500]}
			paginationComponentOptions={{
				rowsPerPageText: "Hiển thị:",
				rangeSeparatorText: "của",
			}}
			noDataComponent="Không có dữ liệu..."
			{...props}
		/>
	);
}

export default DataTables;
