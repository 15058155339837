import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function HomeAdmin() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Tổng quan";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [navigate]);


    return (
        <div className='container'>
            Tổng quan
        </div>
    )
}

export default HomeAdmin;




