import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Form, Col, Row } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../../other/DataTables';
// import PostsAddModal from './PostsAddModal';
import ProductEditModal from './ProductEditModal';
import ProductAddModal from './ProductAddModal';
// import PostsEditModal from './PostsEditModal';
import moment from 'moment';
import swal from 'sweetalert';

const Product = () => {
    const navigate = useNavigate();
    const [filterText, setfilterText] = useState("")

    useEffect(() => {
        document.title = "Sản phẩm";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getPostAll();
        getTopicAll();

    }, [navigate]);
    const [categories, setCategories] = useState([])
    const [posts, setPosts] = useState([]);
    const [showPostsAddModal, setShowPostsAddModal] = useState(false);
    const [showPostEditModal, setShowPostEditModal] = useState(false);
    const [idEdit, setIdEdit] = useState('');

    // const [selectedRows, setselectedRows] = useState([]);
    // const [alertShow, setalertShow] = useState(false);
    const toSlug = (str) => {
        if (str !== 'undefined') {
            str = str.toLowerCase(); // Chuyển hết sang chữ thường       
            str = str
                .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
                .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
            str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
            str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
        }

        return str;
    }
    const getPostAll = async () => {
        await axios.get(`/product/list`).then((res) => {
            if (res.data.errCode === 0) {
                setPosts(res.data.products);
            } else {
                toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const editPost = (id) => {
        setShowPostEditModal(true);
        setIdEdit(id);
    }
    const getTopicAll = async () => {
        var arr=[]
        
        await axios.get(`/category/list`).then((res) => {
            if (res.data.errCode === 0) {
                res.data.categories?.map(e=> 
                    arr.push({
                        value:e.id,
                        label:e.name
                    }))
                setCategories(arr);

            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }
    const deletePost = async (id, name) => {
        await swal({
            title: "Bạn muốn xóa Sản phẩm?",
            text: name,
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                await axios.delete(`/product/delete/${id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        getPostAll();
                        toast(res.data.errMessage, { type: 'success' });
                    } else {
                        toast(res.data.errMessage, { type: 'error' });
                    }
                }).catch((error) => {
                    toast('Lỗi', { type: 'error' });
                });
            }
        })

    }

    const showPostAll = () => {
        const columns = [
            {
                name: "Tiêu đề",
                selector: (row) => row.titleVi,
                sortable: true,
                // style: {
                //     div: {
                //         maxWidth: '100px !important'
                //       }
                //     }
                  
            },

            {
                name: "Slug",
                selector: (row) =>  
                //  <div dangerouslySetInnerHTML={{ __html: row.slug }}></div>
                row.slug,
                // sortable: true,
                // cell:row=>
                // <div dangerouslySetInnerHTML={{ __html: row.slug }}></div>
            },
            // {
            //     name: "Mô tả",
            //     selector: (row) =>  <div dangerouslySetInnerHTML={{ __html: row.description }}></div>,
            //     // sortable: true,
            //     // cell:row=>
            //     // <div dangerouslySetInnerHTML={{ __html: row.description }}></div>
            //     // right: true,
            //     // cell: row => <>{moment(row.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</>
            // },
            {
                name: "Phương thức",
                // selector: (row) => row.setting,
                center: true,

                cell: row => <>
                    <Button onClick={() => editPost(row.id)} variant='outline-warning' size="sm" className="me-2" type="button">
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>

                    <Button onClick={() => deletePost(row.id, row.titleVi)} variant='outline-danger' size="sm" type="button">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = [];

        // const selectedRows = (items) => {
        //     setselectedRows(items);
        //     setalertShow(true);
        // };

        if (posts.length > 0) {
            posts.map((item, i) => {
                data.push({
                    id: item.id,
                    titleVi: item.name,
                    description: item.description,
                    slug: item.slug,
                });
                return item;
            });
        }
        const filteredItems = data.filter((item) => {

            if (
                (toSlug(item.titleVi)).includes(toSlug(filterText))
                //  ||
                // (toSlug(item.topicNameVi)).includes(toSlug(filterText))
            ) {

                return item;

            }
            return false
        }


        );
        return (<>
            <Row className=' p-2'>
                <Col md={3}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            aria-label="Search"
                            onChange={(e) => {
                                setfilterText(e.target.value)
                            }}
                        />
                        <Button variant="outline-secondary" ><FontAwesomeIcon icon={faSearch} /></Button>
                    </Form>
                </Col>

                <Col md={4}>
                    <Button variant="outline-primary" onClick={() => setShowPostsAddModal(true)}>
                        <FontAwesomeIcon icon={faPlus} /> Thêm
                    </Button>
                </Col>
            </Row>

            <DataTables data={filteredItems} columns={columns}
            // selectedRows={selectedRows} 
            />
        </>
        )
    }

    const onSubmitOk = () => {
        setShowPostsAddModal(false);
        setShowPostEditModal(false);
        getPostAll();
    }

    return (<>
        <div className='container'>
            <Breadcrumb className="p-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Tổng quan
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Sản phẩm</Breadcrumb.Item>
            </Breadcrumb>
            {/* <RenderSelectedRows /> */}

            {showPostAll()}

            {showPostsAddModal ? <ProductAddModal show={showPostsAddModal} onHide={() => setShowPostsAddModal(false)} onSubmitSuccess={onSubmitOk}  categories={categories}/> : <></>}
            {showPostEditModal && idEdit !== '' ? <ProductEditModal show={showPostEditModal} onHide={() => setShowPostEditModal(false)} idEdit={idEdit} onSubmitSuccess={onSubmitOk}categories={categories} /> : <></>}

        </div>
    </>
    )
}

export default Product;




