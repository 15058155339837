import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Form, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../../other/DataTables';
// import TopicAddModal from './TopicAddModal';
// import CategoryAddModal from './CategoryAddModal';
// import CategoryEditModal from './CategoryEditModal';
import CompensateAddModal from './CompensateAddModal';
import TextEditor from '../TextEditor';
import moment from 'moment';
// import TopicEditModal from './TopicEditModal';
import swal from 'sweetalert';
import Select from "react-select";
import CompensateEditModal from './CompensateEditModal';
const Compensate = () => {
    const navigate = useNavigate();
    const [filterText, setfilterText] = useState("")

    useEffect(() => {
        document.title = "Bồi thường";
        window.scrollTo({ top: 0, behavior: 'smooth' });

        getProvince()
        getCompensate(keyTab)
        getBaiViet()

    }, [navigate]);
    const [contentInf, setContentInf] = useState('');

    const [topics, setTopics] = useState([]);
    const [showTopicAddModal, setShowTopicAddModal] = useState(false);
    const [showTopicEditModal, setShowTopicEditModal] = useState(false);
    const [idEdit, setIdEdit] = useState('');
    const [province, setprovince] = useState([])
    const [provinceChoice, setprovinceChoice] = useState({ value: 0, label: '--Chọn tỉnh--' })
    const [district, setdistrict] = useState([])
    const [districtChoice, setdistrictChoice] = useState({ value: 0, label: '--Chọn huyện--' })
    const [ward, setward] = useState([])
    const [wardChoice, setwardChoice] = useState({ value: 0, label: '--Chọn xã/ phường--' })
    const [indemnityAreaInfos, setindemnityAreaInfos] = useState([])
    const [keyTab, setKeyTab] = useState(0)
    const [itemEdit, setItemEdit] = useState({})
    const [baiviet, setbaiviet] = useState([])
    const [contentid, setcontentid] = useState([])

    // const
    // const [selectedRows, setselectedRows] = useState([]);
    // const [alertShow, setalertShow] = useState(false);
    const toSlug = (str) => {
        if (str !== 'undefined') {
            str = str.toLowerCase(); // Chuyển hết sang chữ thường       
            str = str
                .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
                .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
            str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
            str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
        }

        return str;
    }
    const getProvince = async () => {
        var arr = []
        arr.push(
            {
                label: '--Chọn tỉnh--',
                value: 0
            }
        )
        await axios.get(`/province/list`).then((res) => {
            if (res.data.errCode === 0) {
                // setprovince(res.data.provinces);
                res.data.provinces?.map(item => {
                    arr.push({
                        label: item.name,
                        value: item.id
                    })
                })
                setprovince(arr);

            } else {
            }
        }).catch((error) => {
        });
    }
    const getDistrict = (id) => {
        var arr = []
        arr.push(
            {
                label: '--Chọn huyện--',
                value: 0
            }
        )
        axios.get(`/district/list?provinceId=${id}`).then((res) => {
            if (res.data.errCode === 0) {
                res.data.districs?.map(item => {
                    arr.push({
                        label: item.name,
                        value: item.id
                    })
                })
                setdistrict(arr);

            } else {
            }
        }).catch((error) => {
        });
    }
    const getWard = (id_province, id_district) => {
        var arr = []
        arr.push(
            {
                label: '--Chọn xã/ phường--',
                value: 0
            }
        )
        axios.get(`/ward/list?provinceId=${id_province}&districtId=${id_district}`).then((res) => {
            if (res.data.errCode === 0) {
                // setprovince(res.data.provinces);
                res.data.wards?.map(item => {
                    arr.push({
                        label: item.name,
                        value: item.id
                    })
                })
                setward(arr);

            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const getBaiViet = async () => {
        await axios.get(`/post/list?limit=1&page=1&topicId=2`).then((res) => {
            if (res.data.errCode === 0) {
                getBaiVietChiTiet(res.data.posts[0].id)
                // axios.get(`/post/${res.data.posts[0].id}`).then((res) => {
                //     if (res.data.errCode === 0) {
                //         // setprovince(res.data.provinces);

                //         setbaiviet(res.data.post);

                //         setContentInf(res.data.post.content)
                //     } else {
                //     }
                // }).catch((error) => {
                // });
                setcontentid(res.data.posts[0].id)
            } else {
            }
        }).catch((error) => {
        });
    }
    const getBaiVietChiTiet = (id) => {
        axios.get(`/post/${id}`).then((res) => {
            if (res.data.errCode === 0) {
                // setprovince(res.data.provinces);

                setbaiviet(res.data.post);

                setContentInf(res.data.post.content ? res.data.post.content : [])
            } else {
            }
        }).catch((error) => {
        });
    }
    const getCompensate = (id_category) => {

        axios.get(`indemnityAreaInfo/list?indemnityCategory=${id_category}&ward=${wardChoice.value}&district=${districtChoice.value}&province=${provinceChoice.value}`).then((res) => {
            if (res.data.errCode === 0) {
                setindemnityAreaInfos(res.data.indemnityAreaInfos);

            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }


    const editTopic = (item) => {
        setShowTopicEditModal(true);
        setIdEdit(item);
        setItemEdit(item)
      
    }

    const deleteTopic = async (id, name, type) => {
        await swal({
            title: "Bạn muốn xóa đơn vị bồi thường?",
            text: name,
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                await axios.delete(`/indemnityAreaInfo/delete/${id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        getCompensate(type)
                        toast(res.data.errMessage, { type: 'success' });
                    } else {
                        toast(res.data.errMessage, { type: 'error' });
                    }
                }).catch((error) => {
                    // toast(error.message, { type: 'error' });
                });
            }
        })

    }

    const showTopicAll = () => {
        // getCompensate(1)
        const columns = [
            {
                name: "Tên bệnh viện",
                selector: (row) => row.nameVi,
                sortable: true,
                wrap: true
            },
            {
                name: "Số điện thoại",
                selector: (row) => row.phone,
                sortable: true,
            }, {
                name: "Địa chỉ",
                selector: (row) => row.number,
                sortable: true,
                wrap: true
            }, {
                name: "Xã/ phường",
                selector: (row) => row.ward,
                sortable: true,
                wrap: true
            }, {
                name: "Huyện",
                selector: (row) => row.district,
                sortable: true,
                wrap: true
            }, {
                name: "Tỉnh",
                selector: (row) => row.province,
                sortable: true,
                wrap: true
            },
            {
                name: "Phương thức",
                center: true,

                cell: row => <>
                    <Button onClick={() => editTopic(row)} variant='outline-warning' size="sm" className="me-2" type="button">
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>

                    <Button onClick={() => deleteTopic(row.id, row.nameVi, 1)} variant='outline-danger' size="sm" type="button">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = [];

        if (indemnityAreaInfos.length > 0) {
            indemnityAreaInfos.map((item, i) => {
                data.push({
                    id: item.id,
                    nameVi: item.name,
                    phone: item.phonenumber,
                    ward: item.ward,
                    district: item.district,
                    province: item.province,
                    number: item.address
                    // updatedAt: item.updatedAt,
                });
                return item;
            });
        }
        const filteredItems = data.filter((item) => {

            if (
                (toSlug(item.nameVi)).includes(toSlug(filterText))
            ) {

                return item;

            }
            return false
        }


        );
        return (<>
            <Row className='p-2'>

                <Col md={2}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            aria-label="Search"
                            onChange={(e) => {
                                setfilterText(e.target.value)
                            }}
                        />

                    </Form>
                </Col>
                <Col md={3} style={{ zIndex: 4 }}>
                    < Select
                        options={province}
                        defaultValue={provinceChoice}
                        value={provinceChoice}
                        onChange={(choice) => {
                            setprovinceChoice(choice)
                            setdistrictChoice({
                                value: 0, label: '--Chọn huyện'
                            })
                            setwardChoice({
                                value: 0, label: '--Chọn xã/ phường'
                            })
                            getDistrict(choice.value)
                        }}


                    >
                    </Select >
                </Col>
                <Col md={3} style={{ zIndex: 3 }}>
                    < Select
                        options={district}
                        defaultValue={district[0]}
                        value={districtChoice}
                        onChange={(choice) => {
                            setdistrictChoice(choice)
                            getWard(provinceChoice.value, choice.value)
                            setwardChoice({
                                value: 0, label: '--Chọn xã/ phường'
                            })
                        }}
                        isDisabled={provinceChoice.value == 0}

                    >
                    </Select >
                </Col>
                <Col md={3} style={{ zIndex: 2 }}>
                    < Select
                        options={ward}
                        defaultValue={ward[0]}
                        value={wardChoice}
                        onChange={(choice) => {
                            setwardChoice(choice)
                        }}
                        isDisabled={districtChoice.value == 0}

                    >
                    </Select >

                </Col>

                <Col md={1}>
                    <Button variant="outline-secondary" onClick={() => getCompensate(1)} ><FontAwesomeIcon icon={faSearch} /></Button>

                </Col>
                {/* <Col md={2}>
                    <Button variant="outline-primary" onClick={() => setShowTopicAddModal(true)}>
                        <FontAwesomeIcon icon={faPlus} /> Thêm
                    </Button>
                </Col> */}
            </Row>

            <DataTables data={filteredItems} columns={columns}
            // selectedRows={selectedRows} 
            />
        </>
        )
    }
    const showGarageAll = () => {
        //    getCompensate(2)
        const columns = [
            {
                name: "Tên gara",
                selector: (row) => row.nameVi,
                sortable: true,
                wrap: true
            },
            {
                name: "Số điện thoại",
                selector: (row) => row.phone,
                sortable: true,
                wrap: true
            }, {
                name: "Địa chỉ",
                selector: (row) => row.number,
                sortable: true,
                wrap: true
            }, {
                name: "Xã/ phường",
                selector: (row) => row.ward,
                sortable: true,
                wrap: true
            }, {
                name: "Huyện",
                selector: (row) => row.district,
                sortable: true,
                wrap: true
            }, {
                name: "Tỉnh",
                selector: (row) => row.province,
                sortable: true,
                wrap: true
            },
            {
                name: "Phương thức",
                // selector: (row) => row.setting,
                center: true,

                cell: row => <>
                    <Button onClick={() => editTopic(row)} variant='outline-warning' size="sm" className="me-2" type="button">
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>

                    <Button onClick={() => deleteTopic(row.id, row.nameVi, 2)} variant='outline-danger' size="sm" type="button">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = [];

        // const selectedRows = (items) => {
        //     setselectedRows(items);
        //     setalertShow(true);
        // };

        if (indemnityAreaInfos.length > 0) {
            indemnityAreaInfos.map((item, i) => {
                data.push({
                    id: item.id,
                    nameVi: item.name,
                    phone: item.phonenumber,
                    ward: item.ward,
                    district: item.district,
                    province: item.province,
                    number: item.address
                });
                return item;
            });
        }
        const filteredItems = data.filter((item) => {

            if (
                (toSlug(item.nameVi)).includes(toSlug(filterText))
            ) {

                return item;

            }
            return false
        }


        );
        return (<>
            <Row className='p-2'>

                <Col md={2}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            aria-label="Search"
                            onChange={(e) => {
                                setfilterText(e.target.value)
                            }}
                        />

                    </Form>
                </Col>
                <Col md={3} style={{ zIndex: 4 }}>
                    < Select
                        options={province}
                        defaultValue={provinceChoice}
                        value={provinceChoice}
                        onChange={(choice) => {
                            setprovinceChoice(choice)
                            setdistrictChoice({
                                value: 0, label: '--Chọn huyện'
                            })
                            setwardChoice({
                                value: 0, label: '--Chọn xã/ phường'
                            })
                            getDistrict(choice.value)
                        }}


                    >
                    </Select >
                </Col>
                <Col md={3} style={{ zIndex: 3 }}>
                    < Select
                        options={district}
                        defaultValue={district[0]}
                        value={districtChoice}
                        onChange={(choice) => {
                            setdistrictChoice(choice)
                            getWard(provinceChoice.value, choice.value)
                            setwardChoice({
                                value: 0, label: '--Chọn xã/ phường'
                            })
                        }}
                        isDisabled={provinceChoice.value == 0}

                    >
                    </Select >
                </Col>
                <Col md={3} style={{ zIndex: 2 }}>
                    < Select
                        options={ward}
                        defaultValue={ward[0]}
                        value={wardChoice}
                        onChange={(choice) => {
                            setwardChoice(choice)
                        }}
                        isDisabled={districtChoice.value == 0}

                    >
                    </Select >

                </Col>

                <Col md={1}>
                    <Button variant="outline-secondary" onClick={() => getCompensate(2)} ><FontAwesomeIcon icon={faSearch} /></Button>

                </Col>
                {/* <Col md={2}>
                    <Button variant="outline-primary" onClick={() => setShowTopicAddModal(true)}>
                        <FontAwesomeIcon icon={faPlus} /> Thêm
                    </Button>
                </Col> */}
            </Row>

            <DataTables data={filteredItems} columns={columns}
            // selectedRows={selectedRows} 
            />
        </>
        )
    }
    const onSubmitOk = () => {
        setShowTopicAddModal(false);
        setShowTopicEditModal(false);
        // getTopicAll();
        getCompensate(keyTab)
    }
    const UpdateBaiViet = () => {
        swal({
            title: "Bạn muốn cập nhật bài viết?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then(async (ok) => {
            if (ok) {
                const data = {

                    slug: baiviet.slug,
                    keywords: baiviet.keywords,
                    banner: null,
                    title: baiviet.title,
                    // titleEn: post.titleEn,
                    description: baiviet.description,
                    showOnHomePage: 0,
                    // descEn: post.descEn,
                    content: contentInf,
                    // contentEn: '',
                    // statusId: post.statusId,
                    topicId: 2
                };

                axios.put(`/post/update/${contentid}`, data).then((res) => {
                    if (res.data.errCode === 1) {
                        // setErrors(res.data.errMessage);

                        toast('Cập nhật hướng dẫn thất bại.', { type: 'error' });
                    } else {
                        // const { onSubmitSuccess } = { ...props };
                        // onSubmitSuccess();

                        toast(res.data.errMessage, { type: 'success' });
                    }
                }).catch((error) => {
                    toast('Lỗi', { type: 'error' });
                });

            }
        })
    }
    const showGui = () => {
        return (
            <>
                {/* <Form.Label>Hướng dẫn chung</Form.Label> */}
                {contentInf ? <TextEditor setContents={contentInf} onChange={(value) => setContentInf(value)} placeholder="Hướng dẫn chung" />
                    :
                    'Đang tải'
                    // <TextEditor setContents={contentInf} onChange={(value) => setContentInf(value)} placeholder="Hướng dẫn chung" />

                }    <Button
                    // variant='warning' 
                    style={{ float: 'right' }} onClick={() => UpdateBaiViet()}>Cập nhật</Button>

            </>
        )
    }
    return (<>
        <div className='container'>
            <Breadcrumb className="p-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Tổng quan
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Bồi thường</Breadcrumb.Item>
            </Breadcrumb>
            {/* <RenderSelectedRows /> */}

            {/* <Button >T^heem</Button> */}
            <Button variant="outline-primary" onClick={() => setShowTopicAddModal(true)} style={{ float: 'right' }} hidden={keyTab == 0}>
                <FontAwesomeIcon icon={faPlus} /> Thêm
            </Button>
            <Tabs
                defaultActiveKey="0"
                id="uncontrolled-tab-example"
                className="mb-3  tab-about"
                onSelect={(k) => {
                    getBaiViet()
                    getCompensate(k)
                    setKeyTab(k)
                }}
            >
                {/* <Button>T^heem</Button> */}
                <Tab eventKey="0" title="Hướng dẫn chung "
                // onClick={() => getCompensate(1)}
                >
                    {showGui()}
                </Tab>
                <Tab eventKey="1" title="Hệ thống bệnh viện " onClick={() => getCompensate(1)}>
                    <div style={{ minHeight: '400px ' }}>
                        {showTopicAll()}
                    </div>
                </Tab>
                <Tab eventKey="2" title="Hệ thống gara " onClick={() => getCompensate(2)}>
                    <div style={{ minHeight: '400px ' }}>
                        {showGarageAll()}
                    </div>
                </Tab>
            </Tabs>
            {showTopicAddModal ? <CompensateAddModal show={showTopicAddModal} onHide={() => setShowTopicAddModal(false)} onSubmitSuccess={onSubmitOk} provincelist={province} wardlist={ward} districtlist={district} id_category={keyTab} /> : <></>}
            {showTopicEditModal && itemEdit !== '' ? <CompensateEditModal show={showTopicEditModal} onHide={() => setShowTopicEditModal(false)} 
            item={itemEdit}
            province={province}
            onSubmitSuccess={onSubmitOk} id_category={keyTab} /> : <></>}

        </div>
    </>
    )
}

export default Compensate;




