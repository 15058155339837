import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Form, Col, Row } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../../other/DataTables';
// import TopicAddModal from './TopicAddModal';
import CategoryAddModal from './CategoryAddModal';
import CategoryEditModal from './CategoryEditModal';
import moment from 'moment';
// import TopicEditModal from './TopicEditModal';
import swal from 'sweetalert';

const Category = () => {
    const navigate = useNavigate();
    const [filterText, setfilterText] = useState("")

    useEffect(() => {
        document.title = "Danh mục sản phẩm";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getTopicAll();
      
    }, [navigate]);

    const [topics, setTopics] = useState([]);
    const [showTopicAddModal, setShowTopicAddModal] = useState(false);
    const [showTopicEditModal, setShowTopicEditModal] = useState(false);
    const [idEdit, setIdEdit] = useState('');

    // const [selectedRows, setselectedRows] = useState([]);
    // const [alertShow, setalertShow] = useState(false);
    const toSlug = (str) => {
        if (str !== 'undefined') {
            str = str.toLowerCase(); // Chuyển hết sang chữ thường       
            str = str
                .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
                .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
            str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
            str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
        }

        return str;
    }
    const getTopicAll = async () => {
        await axios.get(`/category/list`).then((res) => {
            if (res.data.errCode === 0) {
                setTopics(res.data.categories);
              
            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const editTopic = (id) => {
        setShowTopicEditModal(true);
        setIdEdit(id);
    }

    const deleteTopic = async (id, name) => {
        await swal({
            title: "Bạn muốn xóa danh mục sản phẩm?",
            text: name,
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                await axios.delete(`/category/delete/${id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        getTopicAll();
                        toast(res.data.errMessage, { type: 'success' });
                    } else {
                        toast(res.data.errMessage, { type: 'error' });
                    }
                }).catch((error) => {
                    toast('Lỗi', { type: 'error' });
                });
            }
        })

    }

    const showTopicAll = () => {
        const columns = [
            {
                name: "Tên danh mục",
                selector: (row) => row.nameVi,
                sortable: true,
            },
            {
                name: "Slug",
                selector: (row) => row.slug,
                sortable: true,
                // right: true,
                // cell: row => <>{moment(row.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</>
            },
            {
                name: "Phương thức",
                // selector: (row) => row.setting,
                center: true,

                cell: row => <>
                    <Button onClick={() => editTopic(row.id)} variant='outline-warning' size="sm" className="me-2" type="button">
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>

                    <Button onClick={() => deleteTopic(row.id, row.nameVi)} variant='outline-danger' size="sm" type="button">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = [];

        // const selectedRows = (items) => {
        //     setselectedRows(items);
        //     setalertShow(true);
        // };

        if (topics.length > 0) {
            topics.map((item, i) => {
                data.push({
                    id: item.id,
                    nameVi: item.name,
                    slug: item.slug,
                });
                return item;
            });
        }
        const filteredItems = data.filter((item) => {

            if (
                (toSlug(item.nameVi)).includes(toSlug(filterText)) 
            ) {

                return item;

            }
            return false
        }


        );
        return (<>
            <Row className='p-2'>
                <Col md={3}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            aria-label="Search"
                            onChange={(e) => {
                                setfilterText(e.target.value)
                            }}
                        />
                        <Button variant="outline-secondary" ><FontAwesomeIcon icon={faSearch} /></Button>
                    </Form>
                </Col>

                <Col md={4}>
                    <Button variant="outline-primary" onClick={() => setShowTopicAddModal(true)}>
                        <FontAwesomeIcon icon={faPlus} /> Thêm
                    </Button>
                </Col>
            </Row>

            <DataTables data={filteredItems} columns={columns}
            // selectedRows={selectedRows} 
            />
        </>
        )
    }

    const onSubmitOk = () => {
        setShowTopicAddModal(false);
        setShowTopicEditModal(false);
        getTopicAll();
    }

    return (<>
        <div className='container'>
            <Breadcrumb className="p-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Tổng quan
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Danh mục sản phẩm</Breadcrumb.Item>
            </Breadcrumb>
            {/* <RenderSelectedRows /> */}

            {showTopicAll()}

            {showTopicAddModal ? <CategoryAddModal show={showTopicAddModal} onHide={() => setShowTopicAddModal(false)} onSubmitSuccess={onSubmitOk} /> : <></>} 
            {showTopicEditModal && idEdit !== '' ? <CategoryEditModal show={showTopicEditModal} onHide={() => setShowTopicEditModal(false)} idEdit={idEdit} onSubmitSuccess={onSubmitOk} /> : <></>} 

        </div>
    </>
    )
}

export default Category;




