import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
// import { Link } from 'react-router-dom';
import { Col, Row, Card, Breadcrumb } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "./blog.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { faHome } from "@fortawesome/free-solid-svg-icons";
const Story = () => {
	useEffect(() => {
		if (posts.length === 0) {
			document.title = "VBI | Câu chuyện bảo hiểm";
			window.scrollTo({ top: 0, behavior: "smooth" });
			getPostAll();
		}
	}, []);

	const [posts, setPosts] = useState([]);

	const getPostAll = async () => {
		await axios
			.get(`/post/list?limit=1000&page=1&topicId=1`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setPosts(res.data.posts);
				} else {
					toast("Lỗi", { type: "error" });
				}
			})
			.catch((error) => {
				toast("Lỗi", { type: "error" });
			});
	};
	const viewPosts1 = () => {
		if (posts.length > 0) {
			return posts.map((item, i) => {
				return (
					<Col sm={3} xs={6} key={`postItem${i}`} className="">
						<Card style={{height: "100%"}}>
							<Card.Img
								variant="top"
								className="image-story"
								src={item.banner}
							/>
							<Card.Body>
								<Card.Title className="text-wrap">
									<Link
										className="text-dark"
										to={`/cau-chuyen/${item.slug}`}
									>
										<span
											onClick={() =>
												localStorage.setItem(
													"id_bai-viet",
													item.id,
												)
											}
										>
											{item.title}
										</span>
									</Link>
								</Card.Title>
								<Card.Text className="limited-text-3 text-muted ">
									{item.description}
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				);
			});
		}
	};

	return (
		<>
			<div className="detail_bg" id="page_blog">
				<Breadcrumb className="py-2">
					<Breadcrumb.Item href="/">
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item active>Câu chuyện bảo hiểm</Breadcrumb.Item>
				</Breadcrumb>
				<div className="text_title">
					<h1>Câu chuyện bảo hiểm</h1>
				</div>
			</div>
			<Row className="mb-3">{viewPosts1()}</Row>
		</>
	);
};

export default Story;
