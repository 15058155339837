import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, FloatingLabel, Form, Image, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormatData from '../../other/FormatData';
import axios from 'axios';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import TextEditor from '../TextEditor';

const PostsEditModal = (props) => {
    const navigate = useNavigate();
    const id = props.idEdit || '';
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImagepost, setSelectedImagepost] = useState(null);
    const [post, setPost] = useState({
        topicId: '',
        authId: localStorage.getItem('auth_id'),
        slug: '',
        keyword: '',
        image: '',
        titleVi: '',
        descVi: '',
        statusId: '',
        showOnHomePage: ''
    });
    useEffect(() => {
        getPost();

    }, [navigate]);

    const [topics, setTopics] = useState([]);

    const [errors, setErrors] = useState([]);
    const [contentVi, setContentVi] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getPost = async () => {
        if (id !== '') {
            setIsLoading(true);
            await axios.get(`/post/${id}`).then((res) => {
                if (res.data.errCode === 0) {
                    let postData = res.data.post;

                    setPost({
                        authId: postData.author.id,
                        slug: postData.slug,
                        keyword: postData.keywords,
                        image: postData.banner,
                        titleVi: postData.title,
                        descVi: postData.description,

                        showOnHomePage: postData.showOnHomePage
                    });
                    setSelectedImage(postData.banner)
                    setContentVi(postData.content);
                    setIsLoading(false);
                } else {
                    toast(res.data.message, { type: 'error' });
                    setIsLoading(false);
                }
            }).catch((error) => {
                toast(error.message, { type: 'error' });
                setIsLoading(false);
            });
        }
    }

    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;
        if (e.target.name === "showOnHomePage") { value = e.target.checked }
        post[field] = value;
        delete (errors[field]);

        if (field === 'titleVi') {
            let convertToSlug = FormatData.convertToSlug(value);

            delete (errors?.slug);

            post.slug = convertToSlug;
        }

        setPost({ ...post });
        setErrors({ ...errors });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await swal({
            title: "Bạn muốn cập nhật bài viết?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then(async (ok) => {
            if (ok) {
                const data = {
                    slug: post.slug,
                    keywords: post.keyword,
                    image: selectedImagepost,
                    title: post.titleVi,
                    description: post.descVi,
                    showOnHomePage: post.showOnHomePage,
                    content: contentVi,
                    topicId:1
                };

                await axios.put(`/post/update/${id}`, data).then((res) => {
                    if (res.data.errCode === 1) {
                        setErrors(res.data.errMessage);

                        toast('Cập nhật bài viết thất bại.', { type: 'error' });
                    } else {
                        const { onSubmitSuccess } = { ...props };
                        onSubmitSuccess();

                        toast(res.data.errMessage, { type: 'success' });
                    }
                }).catch((error) => {
                    toast('Lỗi', { type: 'error' });
                });

            }
        })
    }

    const handleChange = (value) => {
        delete (errors?.contentVi);
        setErrors({ ...errors });
        setContentVi(value);
    }
    
    const handleSelectFile = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setSelectedImage(`data:image/jpeg;base64,${base64String}`);
                setSelectedImagepost(base64String)
            };
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setSelectedImage(`data:image/jpeg;base64,${base64String}`);
                setSelectedImagepost(base64String)
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết bài viết</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Row>

                            <Col md={12}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" value={localStorage.getItem('auth_full_name')} readOnly />
                                    <Form.Label>Người đăng</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={12}>
                                <Form.Floating className="mb-3 d-flex ">
                                    <Form.Check type="checkbox"
                                        value={post.showOnHomePage}
                                        name="showOnHomePage"
                                        onChange={(e) => handleInput(e)}
                                        checked={post.showOnHomePage}
                                    />
                                    <Form.Label
                                        style={{
                                            paddingLeft: '1.4rem', top: '-1rem', paddingBottom: '1.3rem'
                                        }}

                                    >Hiện bài viết ở trang chủ</Form.Label>
                                </Form.Floating>


                            </Col>
                        </Row>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)} name="titleVi" value={post.titleVi} className={errors?.titleVi ? 'is-invalid' : ''} placeholder="Tên chủ đề" />
                            <Form.Label>Tiêu đề<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors?.titleVi ? errors?.titleVi : ''}</span>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" defaultValue={post.slug} className={errors?.slug ? 'is-invalid' : ''} placeholder="Slug" readOnly />
                            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors?.slug ? errors?.slug : ''}</span>
                        </Form.Floating>

                        <Row>
                            <Col md={6}>
                                <div>
                                    <label className="btn btn-primary mb-2" style={{ fontSize: 12, cursor: "pointer" }}
                                        htmlFor="banner-top"> Thêm banner bài viết</label>
                                    <input
                                        type="file"
                                        id="banner-top"
                                        className="d-none"
                                        accept='image/*'

                                        multiple={false}
                                        onChange={(e) => handleSelectFile(e)}

                                    />

                                </div>
                            </Col>

                            <Col md={4}>
                                {selectedImagepost ?
                                    <Image className='mb-3' src={`data:image/jpeg;base64,${selectedImagepost}`} thumbnail height={'60px'} />
                                    : <Image className='mb-3' src={selectedImage}
                                        thumbnail height={'60px'} />
                                }


                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FloatingLabel className='mb-3' label="Từ khóa">
                                    <Form.Control
                                        as="textarea"
                                        onChange={(e) => handleInput(e)}
                                        defaultValue={post.keyword}
                                        name="keyword"
                                        placeholder="Từ khóa"
                                        style={{ height: '100px' }}
                                    />
                                    <span className='text-danger'>{errors?.keyword ? errors?.keyword : ''}</span>
                                </FloatingLabel>
                            </Col>

                            <Col md={6}>
                            <Form.Floating className="mb-3">

                                <Form.Control
                                    as="textarea"
                                    onChange={(e) => handleInput(e)}
                                    defaultValue={post.descVi}
                                    name="descVi"
                                    style={{ height: '100px',paddingTop:'25px' }}
                                />
                                <span className='text-danger'>{errors?.descVi ? errors?.descVi : ''}</span>
                                <Form.Label style={{zIndex:'2',opacity:'1', background:'white',paddingBottom:'4px', height:'1.6rem',paddingTop:0
                                ,marginTop:'8px',width:'110%'}}>
                                    Mô tả
                                </Form.Label>
                                </Form.Floating>
                            </Col>
                        </Row>

                        <Form.Label>Nội dung<span className='text-danger'>*</span></Form.Label>

                        {isLoading ? <i>Đang tải...</i> :
                            <TextEditor setContents={contentVi} onChange={handleChange} placeholder="Nội dung" />}
                        <span className='err-input'>{errors?.content ? errors?.content : ''}</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Cập nhật
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    );
}

export default PostsEditModal;