import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const UserAddModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        getAllcodes();

    }, [navigate]);

    const [user, setUser] = useState({
        fullname: '',
        phonenumber: '',
        email: '',
        username: '',
        password: '',
        gender: true,
        dateOfBirth: '1970-01-01'
    });

    const [errors, setErrors] = useState([]);
    const [allcodes, setAllcodes] = useState([]);
    const [isShowPassword, setIsShowPassword] = useState(true);

    const getAllcodes = async () => {
    }

    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        user[field] = value;
        setUser({ ...user });

        delete (errors[field]);
        setErrors({ ...errors });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            fullname: user.fullname,
            phonenumber: user.phonenumber,
            email: user.email,
            username: user.username,
            password: user.password,
            gender: user.gender,
            dateOfBirth: user.dateOfBirth,
        };

        await axios.post(`/user/create`, data).then((res) => {
            if (res.data.errCode === 1) {
                console.log()
                setErrors(res.data.errMessage
                );
                toast(res.data.errMessage.username ? 'Tài khoản ' + res.data.errMessage.username : null, { type: 'error' });
                toast(res.data.errMessage.fullname ? 'Họ và tên ' + res.data.errMessage.fullname : null, { type: 'error' });

                toast(res.data.errMessage.gender ? 'Giới tính ' + res.data.errMessage.gender : null, { type: 'error' });

                toast(res.data.errMessage.email ? 'Email ' + res.data.errMessage.email : null, { type: 'error' });

                toast(res.data.errMessage.phonenumber ? 'Số điện thoại ' + res.data.errMessage.phonenumber : null, { type: 'error' });
                toast(res.data.errMessage.password ? 'Mật khẩu ' + res.data.errMessage.password : null, { type: 'error' });



                // toast('Thêm thành viên thất bại.', { type: 'error' });
            } else {

                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();

                toast(res.data.message, { type: 'success' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }
    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Thêm thành viên</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit} autoComplete="off ">
                    <Modal.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={(e) => handleInput(e)} name="fullname"
                                        value={user.fullname}
                                        className={errors?.fullname ? 'err-input-form' : ''}
                                        placeholder="Họ và tên"
                                        required
                                    />
                                    <Form.Label>Họ và tên<i className="text-danger">*</i></Form.Label>
                                    <span className='err-input'>{errors?.fullname ? errors?.fullname : ''}</span>
                                </Form.Floating>
                            </Col>

                            <Col md={6}>
                                <FloatingLabel label={<>Giới tính
                                </>}>
                                    <Form.Select onChange={(e) => handleInput(e)} value={user.gender} name="gender"
                                    >
                                        <option key={`genderOption0`} value={0}>Khác</option>

                                        <option key={`genderOption1`} value={true}>Nam</option>
                                        <option key={`genderOption2`} value={false}>Nữ</option>

                                    </Form.Select>

                                    <span
                                        className='text-danger'>
                                    </span>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={(e) => handleInput(e)} name="email" value={user.email} required
                                        className={errors?.email ? 'err-input-form' : ''}
                                        placeholder="Email" autoComplete='off ' />
                                    <Form.Label>Email<i className="text-danger">*</i></Form.Label>

                                    <span className='err-input'>{errors?.email ? errors?.email : ''}</span>
                                </Form.Floating>
                            </Col>

                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={(e) => handleInput(e)} name="phonenumber" value={user.phonenumber} required
                                        className={errors?.phonenumber ? 'err-input-form' : ''}
                                        placeholder="Số điện thoại" />
                                    <Form.Label>Số điện thoại<i className="text-danger">*</i></Form.Label>
                                    <span className='err-input'>{errors?.phonenumber ? errors?.phonenumber : ''}</span>
                                </Form.Floating>
                            </Col>
                        </Row>


                        <Row>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={(e) => handleInput(e)} name="username" value={user.username} required
                                        className={errors?.username ? 'err-input-form' : ''}
                                        placeholder="Tài khoản" />
                                    <Form.Label>Tài khoản<i className="text-danger">*</i></Form.Label>
                                    <span className='err-input'>{errors?.username ? errors?.username : ''}</span>
                                </Form.Floating>
                            </Col>

                            <Col md={6}>
                                <Form.Floating className="mb-3  input-group">
                                    <Form.Control
                                        onChange={(e) => handleInput(e)}
                                        name='password'
                                        value={user.password}
                                        type={isShowPassword ? "password" : "text"}
                                        autoComplete="new-password"
                                        placeholder="Mật khẩu"
                                        required
                                    />

                                    <InputGroup.Text className='btn-eye'

                                        onClick={() => setIsShowPassword(!isShowPassword)} style={{ width: '3rem', cursor: 'pointer' }} title={isShowPassword ? 'Hiện' : 'Ẩn'} >
                                        <FontAwesomeIcon icon={isShowPassword ? faEyeSlash : faEye} />
                                    </InputGroup.Text>
                                    <Form.Label >
                                        Mật khẩu<i className="text-danger">*</i>
                                    </Form.Label>
                                    <span className='err-input '>{errors?.password ? errors?.password : ''}</span>

                                </Form.Floating>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default UserAddModal;