import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {  Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./home.scss";
import HeadMeta from "../../other/HeadMeta";
import ContactModal from "./ContactModal";
import axios from "axios";
import ModalSupport from "./ModalSupport";

import { Card } from "react-bootstrap";
import Feedback from "./Feedback";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsiveBanner = {
	desktop: {
		breakpoint: { max: 3000, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const responsiveProduct = {
	desktop: {
		breakpoint: { max: 3000, min: 992 },
		items: 3,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 992, min: 768 },
		items: 2,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 768, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

function HomeIndex() {
	const [showModalSupport, setShowModalSupport] = useState(false);
	const [showContactModal, setShowContactModal] = useState(false);
	const [posts, setPosts] = useState([]);
	useEffect(() => {
		document.title = "VBI | Bảo hiểm VietinBank";
		getPostAll();
		getAllProduct();
		getAllCategory();
	}, []);
	const [dataBanner, setdataBanner] = useState([]);
	const getAllCategory = () => {
		var arr = [];
		axios
			.get(`/category/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					res.data.categories?.map((item) => {
						arr.push({
							id: item.id,
							src: item.banner,
						});
					});
					setdataBanner(arr);
				} else {
					toast("Lỗi", { type: "error" });
				}
			})
			.catch((error) => {
				toast("Lỗi", { type: "error" });
			});
	};
	const [product, setproduct] = useState([]);

	const getAllProduct = () => {
		var arr = [];
		axios
			.get(`/product/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					res.data.products?.map((item) => {
						arr.push({
							id: item.id,
							title: item.name,
							image: item.banner1,
							slug: item.slug,
						});
					});

					setproduct(arr);
				} else {
					toast("Lỗi", { type: "error" });
				}
			})
			.catch((error) => {
				toast("Lỗi", { type: "error" });
			});
	};

	const meta = {
		title: "VBI | Bảo hiểm VietinBank",
		description: "VBI - Bảo hiểm VietinBank",
		keywords: "VBI - Bảo hiểm VietinBank",
		og_image: "/favicon.ico",
		fb_app_id: "",
	};
	const getPostAll = async () => {
		await axios
			.get(`/post/homePage`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setPosts(res.data.posts);
				} else {
					toast("Lỗi", { type: "error" });
				}
			})
			.catch((error) => {
				toast("Lỗi", { type: "error" });
			});
	};
	const viewPosts = () => {
		if (posts.length > 0) {
			return posts.map((item, i) => {
				return (
					<Col sm={3} xs={6} key={`postItem${i}`} >
						<Card style={{height: "100%"}}>
							<Card.Img
								variant="top"
								className="image-story"
								src={item.banner}
							/>
							<Card.Body>
								<Card.Title className="text-wrap">
									<Link
										className="text-dark"
										to={`/cau-chuyen/${item.slug}`}
									>
										<span
											onClick={() =>
												localStorage.setItem(
													"id_bai-viet",
													item.id,
												)
											}
										>
											{item.title}
										</span>
									</Link>
								</Card.Title>
								<Card.Text className="limited-text-3 text-muted ">
									{item.description}
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				);
			});
		}
	};
	return (
		<>
			<HeadMeta {...meta} />

			{showContactModal ? (
				<ContactModal
					show={showContactModal}
					onHide={() => setShowContactModal(false)}
				/>
			) : null}

		<Carousel responsive={responsiveBanner} autoPlay infinite={true}>
			{dataBanner.map((item) => {
				return (
					<div
						key={item.id}
						style={{
							width: "100%",
							aspectRatio: "828/191",
							backgroundImage: `url("${item.src}")`,
							backgroundPosition: "center",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat"
						}}
					/>
				);
			})}
		</Carousel>

			<Row className="pt-3 align-items-center">
				<Col md={8} className="mb-3">

					<Carousel responsive={responsiveProduct} autoPlay infinite={true}>
						{product.map((item) => {
							return (
								<div className="px-2">
									<Link to={`/san-pham/${item.slug}`} >
										<div
											key={item.id}
											style={{
												width: "100%",
												height: "10rem",
												backgroundImage: `url("${item.image}")`,
												backgroundPosition: "center",
												backgroundSize: "cover",
												backgroundRepeat: "no-repeat"
											}}
											onClick={() => {
												localStorage.setItem("id_product_detail", item.id)
											}}
										/>
									</Link>
								</div>
							);
						})}
					</Carousel>

				</Col>
				<Col md={4} className="bg-white">
					<div className="title-product">
						<h1>SẢN PHẨM TIÊU BIỂU</h1>
						<div class="underline"></div>
					</div>

					<p className="des">
						Bảo hiểm VietinBank (VBI) là công ty bảo hiểm phi nhân
						thọ năng động và có tốc độ tăng trưởng nhanh nhất trên
						thị trường trong 5 năm trở lại đây ở Việt Nam.
						<br />
						VBI tiền thân là Công ty TNHH MTV Bảo hiểm Ngân hàng
						TMCP Công thương VN trực thuộc VietinBank – là ngân hàng
						nằm trong TOP 3 có quy mô lớn nhất Việt Nam. Thành lập
						năm 2008 với sứ mệnh bảo toàn giá trị cuộc sống, VBI
						luôn nỗ lực cải tiến sản phẩm dịch vụ để mang lại những
						trải nghiệm giá trị, khác biệt cho khách hàng.{" "}
					</p>
					<center>
						<Button
							onClick={() => setShowModalSupport(true)}
							className="mb-3"
						>
							Tư vấn mua hàng
						</Button>
					</center>
				</Col>
			</Row>
			<div className="story">
				<div className="title-product">
					<h1>CÂU CHUYỆN BẢO HIỂM</h1>
					<div class="underline"></div>
				</div>
				<Row
					style={{
						overflow: "scroll",
						display: "flex",
						flexWrap: "nowrap",
					}}
					
				>
					{viewPosts()}
				</Row>
			</div>
			<div className="py-3">
				<div className="title-product">
					<h1>PHẢN HỒI CỦA KHÁCH HÀNG</h1>
					<div class="underline"></div>
				</div>

				<Feedback />
			</div>
			{showModalSupport ? (
				<ModalSupport
					show={showModalSupport}
					onHide={() => setShowModalSupport(false)}
				/>
			) : null}
		</>
	);
}

export default HomeIndex;
