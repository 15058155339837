import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons";

const ModalSupport = (props) => {
	const form = useRef();
	const navigate = useNavigate();
	const [err, setErr] = useState([]);
	const [user, setUser] = useState({
		fullname: "",
		phonenumber: "",
		email: "",
		content: "",
	});
	const [sendmailable, setsendmailable] = useState(0);
	const sendEmail = (e) => {
		e.preventDefault();
		const data = {
			fullname: user.fullname.trim(),
			email: user.email,
			phonenumber: user.phonenumber,
			content: user.content,
		};
		setsendmailable(1);
		axios
			.post(`/sendMail`, data)
			.then((res) => {
				if (res.data.errCode === 0) {
					setsendmailable(0);
					toast(res.data.errMessage, { type: "success" });
					// setsendmailable(1)
					formClose();
				} else {
					setsendmailable(0);
					toast(res.data.errMessage, { type: "error" });
					setErr(res.data.errMessage);

					// toast(res.data.errMessage.email ? 'Email ' + res.data.errMessage.email : null, { type: 'error' });

					// toast(res.data.errMessage.phonenumber ? 'Số điện thoại ' + res.data.errMessage.phonenumber : null, { type: 'error' });
				}
			})
			.catch((error) => {
				// setsendmailable(0)
				toast("Lỗi", { type: "error" });
			});
	};
	const handleInput = (e) => {
		let value = e.target.value;
		let field = e.target.name;
		err[field] = "";

		user[field] = value;
		setUser({ ...user });
		setErr({ ...err });
	};
	const formClose = () => {
		const { onHide } = { ...props };
		return onHide();
	};

	return (
		<Modal
			show={props.show}
			onHide={formClose}
			backdrop="static"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="fw-bold">
				Liên hệ với chúng tôi
			</Modal.Header>
			<Form onSubmit={sendEmail}>
				<Modal.Body>
					<Row>
						<p className="text-muted text-start">
							Hãy để lại thông tin. VBI sẽ liên hệ với bạn trong
							thời gian sớm nhất!
						</p>
						<Col>
							<Form.Floating className="mb-3">
								<Form.Control
									type="text"
									name="fullname"
									onChange={(e) => handleInput(e)}
									value={user.fullname}
									className={
										err.fullname ? "err-input-form" : ""
									}
									placeholder="Tên của bạn"
									required
								/>
								<Form.Label>
									Tên của bạn<i className="text-danger">*</i>
								</Form.Label>
								<div className="invalid-feedback"></div>
								<span className="err-input">
									{err.fullname}
								</span>
							</Form.Floating>
						</Col>
						<Col>
							<Form.Floating className="mb-3">
								<Form.Control
									type="text"
									name="phonenumber"
									placeholder="Số điện thoại"
									required
									value={user.phonenumber}
									onChange={(e) => handleInput(e)}
									className={
										err.phonenumber ? "err-input-form" : ""
									}
								/>
								<Form.Label>
									Số điện thoại
									<i className="text-danger">*</i>
								</Form.Label>
								<div className="invalid-feedback"></div>
								<span className="err-input">
									{err.phonenumber}
								</span>
							</Form.Floating>
						</Col>
					</Row>

					<Form.Floating className="mb-3">
						<Form.Control
							type="text"
							placeholder="Lời nhắn"
							name="email"
							className={err.email ? "err-input-form" : ""}
							value={user.email}
							onChange={(e) => handleInput(e)}
						/>
						<Form.Label>
							Email <i className="text-danger">*</i>
						</Form.Label>
						<div className="invalid-feedback"></div>
						<span className="err-input">{err.email}</span>
					</Form.Floating>

					<Form.Floating className="mb-3">
						<Form.Control
							type="text"
							placeholder="Lời nhắn"
							name="content"
							value={user.content}
							onChange={(e) => handleInput(e)}
						/>
						<Form.Label>Nội dung cần tư vấn</Form.Label>
						<div className="invalid-feedback"></div>
					</Form.Floating>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => formClose()}>
						<FontAwesomeIcon icon={faTimes} /> Đóng
					</Button>
					<Button
						type="submit"
						variant="success"
						// onClick={() => sendEmail()}
						// sendmailable(0)
						disabled={sendmailable}
					>
						<FontAwesomeIcon icon={faPaperPlane} /> Gửi
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ModalSupport;
