import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Row, Col, Form } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../../other/DataTables';
import UserAddModal from './UserAddModal';
import UserEditModal from './UserEditModal';
import swal from 'sweetalert';

const UserIndex = () => {
    const navigate = useNavigate();
    const [filterText, setfilterText] = useState("")

    useEffect(() => {
        document.title = "Thành viên";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getUserAll();
    }, [navigate]);

    const [users, setUsers] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [idEdit, setIdEdit] = useState('');

    // const [selectedRows, setselectedRows] = useState([]);
    // const [alertShow, setalertShow] = useState(false);
    const   toSlug=(str)=>{
        if(str !== 'undefined' ){
            str = str.toLowerCase(); // Chuyển hết sang chữ thường       
            str = str
              .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
              .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
            str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
            str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
        }
       
        return str;
    }
    const getUserAll = async () => {
        await axios.get(`/user/list`).then((res) => {
            if (res.data.errCode === 0) {
            
                setUsers(res.data.users);
             
            } else {
                toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const editUser = (id) => {
        setIdEdit(id);

        setShowEditModal(true);
    }

    const deleteUser = async (id, fullName) => {
        await swal({
            title: "Bạn muốn xóa Thành viên?",
            text: fullName,
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                await axios.delete(`/user/delete/${id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        getUserAll();
                        toast(res.data.errMessage, { type: 'success' });
                    } else {
                        toast(res.data.errMessage, { type: 'error' });
                    }
                }).catch((error) => {
                    // toast(error.message, { type: 'error' });
                });
            }
        })
    }

    const showUserAll = () => {
        const columns = [
            {
                name: "Họ và tên",
                selector: (row) => row.fullName,
                sortable: true,
            },
            {
                name: "Email",
                selector: (row) => row.email,
                sortable: true,
            },
            // {
            //     name: "Trạng thái",
            //     selector: (row) => row.statusVi,
            //     sortable: true,
            // },
            {
                name: "Phương thức",
                selector: (row) => row.setting,
                center: true,
                cell: row => <>
                    <Button onClick={() => editUser(row.id)} variant='outline-warning' size="sm" className="me-2" type="button">
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>

                    <Button onClick={() => deleteUser(row.id, row.fullName)} variant='outline-danger' size="sm" type="button">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = [];

        // const selectedRows = (items) => {
        //     setselectedRows(items);
        //     setalertShow(true);
        // };

        if (users.length > 0) {
            users.map((item, i) => {
                data.push({
                    id: item.id,
                    fullName: item.fullname,
                    email: item.email,
                    // statusVi: item.statusVi,
                });
                return item;
            });
        }
        const filteredItems = data.filter((item) => {

            if (
                (toSlug(item.fullName)).includes(toSlug(filterText)) ||
                (toSlug(item.email)).includes(toSlug(filterText)) 
            ) {

                return item;

            }
            return false
        }


        );
        return (<>
            <Row className=' p-2'>
                <Col md={3}>
                    <Form className="d-flex" >
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            aria-label="Search"
                            onChange={(e) => {
                                setfilterText(e.target.value)
                            }}
                        />
                        <Button variant="outline-secondary" ><FontAwesomeIcon icon={faSearch} /></Button>
                    </Form>
                </Col>

                <Col md={4}>
                    <Button variant="outline-primary" onClick={() => setShowAddModal(true)}>
                        <FontAwesomeIcon icon={faPlus} /> Thêm
                    </Button>
                </Col>
            </Row>

            <DataTables data={filteredItems} columns={columns}
            // selectedRows={selectedRows} 
            />
        </>)
    }

    // const RenderSelectedRows = () => {
    //     let num = selectedRows.length;
    //     if (num > 0 && alertShow === true) {
    //         return (
    //             <Alert
    //                 style={{
    //                     position: "absolute",
    //                     zIndex: "1",
    //                     left: "91%",
    //                 }}
    //                 variant="info mb-0"
    //                 onClose={() => setalertShow(false)}
    //                 dismissible
    //             >
    //                 {num} mục đã chọn.
    //             </Alert>
    //         );
    //     }
    // }

    const onSubmitOk = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setIdEdit('');

        getUserAll();
    }

    return (
        <div className='container'>
            <Breadcrumb className="p-2 ">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Tổng quan
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Thành viên</Breadcrumb.Item>
            </Breadcrumb>

            {/* <RenderSelectedRows /> */}

            {showUserAll()}

            {showAddModal ? <UserAddModal show={showAddModal} onHide={() => setShowAddModal(false)} onSubmitSuccess={onSubmitOk} /> : <></>}
            {showEditModal && idEdit !== '' ? <UserEditModal show={showEditModal} onHide={() => { setShowEditModal(false); setIdEdit('') }} idEdit={idEdit} onSubmitSuccess={onSubmitOk} /> : <></>}
        </div>
    )
}

export default UserIndex;




