import React, { useState, useEffect } from "react";
import Coverflow from "react-coverflow";
import { useNavigate } from "react-router-dom";
import "./CoverFlow.css";

const MyCoverflow = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (MyCoverflow) {

        // setActiveIndex(prevIndex => (prevIndex + 1) % data.length);
        setActiveIndex((prevState) => ({
          ...prevState,
          prevState: prevState.activeIndex, // Store the previous activeIndex
          activeIndex: (prevState.activeIndex + 1) % data.length,
        }));
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);
  const handleSlideChange = index => {
    if (index > 0)
      setActiveIndex(index);
  };
  const navigate = useNavigate();
  const handleSlideClick = index => {
    if (index === data.length - 1) {

      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };
  return (
    <Coverflow
      offsetW
      className='flow-product'
      displayQuantityOfSide={1}
      navigation={true}
      enableScroll={true}
      clickable={true}
      infiniteScroll={true}
      currentFigureScale={1.2}
      otherFigureScale={0.8}
      activeOnClick
      active={activeIndex}
      onActiveItemClick={handleSlideClick}
      onActiveItemChange={handleSlideChange}
      media={{
        '@media (max-width: 767px)': {
          width: '100%',
          height: '200px'
        },
        '@media (min-width: 768px)': {
          width: '100%',
          height: '400px'
        }
      }}
    >
      {data.map(item => (
        <div
          key={item.id}
          onClick={() => navigate(`/san-pham/${item.slug}`)} 
        >
            {item.image 
            ?
              <img 
                src={item.image} 
                alt={item.title} 
                className="standard-product-image"
              />
            : 
              null}
        </div>
      ))}
    </Coverflow>
  );
};

export default MyCoverflow;
