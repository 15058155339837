import React, { useEffect } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

const Success = () => {
    useEffect(() => {
        document.title = "Đăng ký thành công";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Container>
            <Row className="justify-content-center">
                <h1 className='text_title'>Đăng ký thành công</h1>
                <Col md={8} className="text-center mb-5">
                    <p className="lead">Cảm ơn quý khách đã để lại thông tin.</p>
                    <p className="lead">Sẽ có nhân viên Kafood liên hệ lại ngay ạ.</p>
                    <Link to="/" className='btn btn-primary'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Quay lại
                    </Link>
                </Col>
            </Row>
        </Container>
    );
}

export default Success;