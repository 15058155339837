import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, FloatingLabel, Form, Image, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormatData from '../../other/FormatData';
import axios from 'axios';
import { toast } from 'react-toastify';
import TextEditor from '../TextEditor';

const PostsAddModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        // getTopicAll();
    }, [navigate]);

    const [selectedImage, setSelectedImage] = useState(null);
    const [post, setPost] = useState({
        topicId: '',
        authId: localStorage.getItem('auth_id'),
        slug: '',
        keyword: '',
        image: '',
        titleVi: '',
        titleEn: '',
        descVi: '',
        descEn: '',
        showOnHomePage: '1',
        statusId: ''
    });

    const [errors, setErrors] = useState([]);
    const [contentVi, setContentVi] = useState('');


    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;
        if (e.target.name === "showOnHomePage") { value = e.target.checked }
        post[field] = value;
        delete (errors[field]);

        if (field === 'titleVi') {
            let convertToSlug = FormatData.convertToSlug(value);

            delete (errors?.slug);

            post.slug = convertToSlug;
        }

        setPost({ ...post });
        setErrors({ ...errors });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            slug: post.slug,
            keywords: post.keyword,
            image: selectedImage,
            title: post.titleVi,
            description: post.descVi,
            content: contentVi,
            showOnHomePage: post.showOnHomePage,
            topicId:1
        };
        await axios.post(`/post/create`, data).then((res) => {
            if (res.data.errCode === 1) {
                setErrors(res.data.errMessage);
                toast("Thêm bài viết thất bại", { type: 'error' });
            } else {
                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();

                toast(res.data.errMessage, { type: 'success' });
            }
        }).catch((error) => {
            toast('Lỗi', { type: 'error' });
        });
    }

    const handleChange = (value) => {
        delete (errors?.contentVi);
        setErrors({ ...errors });
        setContentVi(value);
    }
    const handleSelectFile = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setSelectedImage(base64String);
            };
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setSelectedImage(base64String);
            };
            reader.readAsDataURL(e.target.files[0]);


        }
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Thêm bài viết</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" value={localStorage.getItem('auth_full_name')} readOnly />
                                    <Form.Label>Người đăng</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={12}>
                                <Form.Floating className="mb-3 d-flex ">
                                    <Form.Check type="checkbox"
                                        className='px-0'
                                        name="showOnHomePage"
                                        onChange={(e) => handleInput(e)}
                                        checked={post.showOnHomePage}
                                    />
                                    <Form.Label 
                                    style={{
                                        paddingLeft:'1.4rem',top:'-1rem',paddingBottom:'1.3rem'
                                    }}

                                    >Hiện bài viết ở trang chủ</Form.Label>

                                </Form.Floating>
                            </Col>
                        </Row>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)} name="titleVi" value={post?.titleVi} className={errors?.titleVi ? 'is-invalid' : ''} placeholder="Tên chủ đề"
                                required
                            />
                            <Form.Label>Tiêu đề<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors?.titleVi ? errors?.titleVi : ''}</span>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" name="slug" value={post?.slug} className={errors?.slug ? 'is-invalid' : ''} placeholder="Slug" readOnly />
                            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors?.slug ? errors?.slug : ''}</span>
                        </Form.Floating>

                        <Row>
                            <Col md={12}>
                                <div>
                                    <label className="btn btn-primary mb-2" style={{ fontSize: 12, cursor: "pointer" }}
                                        htmlFor="banner-top"> Thêm banner bài viết</label>
                                    <input
                                        type="file"
                                        id="banner-top"
                                        className="d-none"
                                        accept='image/*'

                                        multiple={false}
                                        onChange={(e) => handleSelectFile(e)}

                                    />

                                </div>
                                <Row>
                                    <Col>
                                        {selectedImage && <Button onClick={() => setSelectedImage(null)} variant='outline-secondary' style={{ float: 'right', zIndex: '1', position: 'relative', marginBottom: '-35px' }}>X</Button>}
                                        {selectedImage &&
                                            <img src={`data:image/jpeg;base64,${selectedImage}`} alt="Hình ảnh tải lên" className='image-bannertop-product' />}
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={4}>
                                <Image className='mb-3' src={post?.image} thumbnail height={'60px'} />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FloatingLabel className='mb-3' label="Từ khóa">
                                    <Form.Control
                                        as="textarea"
                                        onChange={(e) => handleInput(e)}
                                        value={post?.keyword}
                                        name="keyword"
                                        placeholder="Từ khóa"
                                        style={{ height: '100px' }}
                                    />
                                    <span className='text-danger'>{errors?.keyword ? errors?.keyword : ''}</span>
                                </FloatingLabel>


                            </Col>

                            <Col md={6}>
                                <FloatingLabel className='mb-3' label="Mô tả">
                                    <Form.Control
                                        as="textarea"
                                        onChange={(e) => handleInput(e)}
                                        value={post?.descVi}
                                        name="descVi"
                                        placeholder="Mô tả"
                                        style={{ height: '100px' }}
                                    />
                                    <span className='text-danger'>{errors?.descVi ? errors?.descVi : ''}</span>
                                </FloatingLabel>

                            </Col>
                        </Row>

                        <Form.Label>Nội dung<span className='text-danger'>*</span></Form.Label>

                        <TextEditor setContents={contentVi} onChange={handleChange} placeholder="Nội dung" className={errors?.content ?'err-input-form':''}/>

                        <span className='err-input'>{errors?.content ? errors?.content : ''}</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default PostsAddModal;