import React from "react";
// import MyCoverflow from "./MyCoverflow";
import MyCoverflow from "./CoverFlow";

const Test = () => {
  const data=[
 
    { id: 1, title: "Item 1", image: "images/5.jpg" },
    { id: 2, title: "Item 2", image: "images/1.jpg" },
    { id: 3, title: "Item 3", image: "images/2.jpg" },


  ]
  return (
    <div>
      <MyCoverflow data={data} />
    </div>
  );
};

export default Test;
