import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormatData from '../../other/FormatData';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import TextEditor from '../TextEditor';
import Select from "react-select";

const ProductAddModal = (props) => {
    const [topic, setTopic] = useState({
        slug: '',
        nameVi: '',
    });

    const [contentVi, setContentVi] = useState('');
    const [contentInf, setContentInf] = useState('');
    const [categoryChoice, setCategoryChoice] = useState(props.categories[0])
    const [contentObj, setContentObj] = useState('');
    const [contentTra, setContentTra] = useState('');
    const [contentDoc, setContentDoc] = useState('');
    const [err, setErr] = useState([])
    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        topic[field] = value;

        if (field === 'nameVi') {
            let convertToSlug = FormatData.convertToSlug(value);

            topic.slug = convertToSlug;
        }
        setTopic({ ...topic });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            categoryId: categoryChoice.value,
            slug: topic.slug,
            name: topic.nameVi.trim(),
            description: contentVi,
            productInfo: contentInf,
            insuranceObject: contentObj,
            indemnityGuide: contentTra,
            document: contentDoc,
            image1: selectedImage,
            image2: selectedImage2
        };
        await axios.post(`/product/create`, data).then((res) => {
            if (res.data.errCode === 1) {
                let topicData = { ...topic };
                topicData.errors = res.data.errMessage;
                setTopic(topicData);
                setErr(res.data.errMessage)
                toast('Thêm sản phẩm thất bại.', { type: 'error' });
            } else {

                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();

                toast(res.data.errMessage, { type: 'success' });
            }
        }).catch((error) => {
            toast('Lỗi', { type: 'error' });
        });
    }
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage1, setSelectedImage1] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);



    const handleSelectFile = (type, e) => {
        if (e.target.files[0]) {
            if (type == 0) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage(base64String);
                };
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage(base64String);
                };
                reader.readAsDataURL(e.target.files[0]);
            }
            if (type == 1) {

                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage1(base64String);
                };
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage1(base64String);
                };
                reader.readAsDataURL(e.target.files[0]);
            }
            if (type == 2) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage2(base64String);
                };
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    setSelectedImage2(base64String);
                };
                reader.readAsDataURL(e.target.files[0]);
            }
        }
    }


    return (
        <>
            <Modal size='xl' show={props.show} onHide={handleClose} backdrop="static" keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>Thêm sản phẩm</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div>
                            <label className="btn btn-primary mb-2" style={{ fontSize: 12, cursor: "pointer" }} htmlFor="banner-top"> Thêm banner-top</label>
                            <input
                                type="file"
                                id="banner-top"
                                className="d-none"
                                accept='image/*'

                                multiple={false}
                                onChange={(e) => handleSelectFile(0, e)}

                            />

                        </div>
                        <Row>
                            <Col>
                                {selectedImage && <Button onClick={() => setSelectedImage(null)} variant='outline-secondary' style={{ float: 'right', zIndex: '1', position: 'relative', marginBottom: '-35px' }}>X</Button>}
                                {selectedImage && <img src={`data:image/jpeg;base64,${selectedImage}`} alt="Hình ảnh tải lên" className='image-bannertop-product' />}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mb-3 mt-3'>
                                < Select
                                    options={props.categories}
                                    defaultValue={props.categories[0]}
                                    name='category'
                                    onChange={(choice) => {
                                        setCategoryChoice(choice)

                                    }}


                                >
                                </Select >
                            </Col>
                        </Row>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)}
                                name="nameVi"
                                value={topic.nameVi}
                                className= {err.name ?'is-invalid'  : ''}
                                placeholder="Tên danh mục" required />
                            <Form.Label>Tên sản phẩm<i className="text-danger">*</i></Form.Label>
                            <span className='err-input'>
                                {err.name ? err.name : ''}
                            </span>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" name="slug" value={topic.slug} placeholder="Slug" readOnly />
                            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>

                        </Form.Floating>

                        <Form.Label>Mô tả <i className="text-danger">*</i></Form.Label>

                        <TextEditor setContents={contentVi} onChange={(value) => setContentVi(value)} placeholder="Mô tả*" />

                        <Form.Label>Thông tin sản phẩm</Form.Label>

                        <TextEditor setContents={contentInf} onChange={(value) => setContentInf(value)} placeholder="Thông tin sản phẩm" />

                        <Form.Label>Đối tượng được bảo hiểm</Form.Label>

                        <TextEditor setContents={contentObj} onChange={(value) => setContentObj(value)} placeholder="Đối tượng được bảo hiểm" />

                        <Form.Label>Hướng dẫn bồi thường</Form.Label>

                        <TextEditor setContents={contentTra} onChange={(value) => setContentTra(value)} placeholder="Hướng dẫn bồi thường" />

                        <Form.Label>Tài liệu</Form.Label>

                        <TextEditor setContents={contentDoc} onChange={(value) => setContentDoc(value)} placeholder="Tài liệu" />

                        <div>
                            <label className="btn btn-primary mt-2" style={{ fontSize: 12, cursor: "pointer" }} htmlFor="banner-end"> Thêm banner-end</label>
                            <input
                                type="file"
                                id="banner-end"
                                className="d-none"
                                accept='image/*'

                                multiple={false}
                                onChange={(e) => handleSelectFile(2, e)}

                            />

                        </div>
                        <Row>
                            <Col>
                                {selectedImage2 && <Button onClick={() => setSelectedImage2(null)} variant='outline-secondary' style={{ float: 'right', zIndex: '1', position: 'relative', marginBottom: '-35px' }}>X</Button>}
                                {selectedImage2 && <img src={`data:image/jpeg;base64,${selectedImage2}`} alt="Hình ảnh tải lên" className='image-bannertop-product' />}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ProductAddModal;