import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faHandHoldingDollar, faNewspaper, faTachometerAlt, faUsers } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  return (
    <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion" >
      <div className="sb-sidenav-menu">
        <div className="nav">
          <NavLink className="nav-link" to="/admin/">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faTachometerAlt} />
            </div>
            Tổng quan
          </NavLink>

          <NavLink className="nav-link" to="/admin/ql-danh-muc-san-pham">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faNewspaper} />
            </div>
          Danh mục sản phẩm
          </NavLink>
          <NavLink className="nav-link" to="/admin/ql-san-pham">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faNewspaper} />
            </div>
          Sản phẩm
          </NavLink>
          <NavLink className="nav-link" to="/admin/post">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faNewspaper} />
            </div>
           Câu chuyện bảo hiểm
          </NavLink>
          <NavLink className="nav-link" to="/admin/compensate">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faHandHoldingDollar} />
            </div>
           Bồi thường
          </NavLink>
          <NavLink className="nav-link" to="/admin/user">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faUsers} />
            </div>
            Thành viên
          </NavLink>
          <NavLink className="nav-link" to="/admin/setting">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faGear} />
            </div>
           Cài đặt 
          </NavLink>
          {/* <NavLink className="nav-link" to="/admin/topic">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faTags} />
            </div>
            Chủ đề bài viết
          </NavLink> */}

        </div>
      </div>
      <div className="sb-sidenav-footer py-2">
        <div className="small">Version: 1.0</div>
      </div>
    </nav>
  );
};

export default Sidebar;
