import React, { useEffect } from 'react';
import './detail.scss';
import HeadMeta from '../../other/HeadMeta';
import { Col, Row } from 'react-bootstrap';

const Detail = () => {
    useEffect(() => {
        document.title = "VBI | Bảo hiểm VietinBank";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    const meta = {
        title: "VBI | Bảo hiểm VietinBank",
        // description: '',
        // keywords: '',
        // og_image: '',
        // fb_app_id: '',
    };

    return (
        <div className='detail_bg' id="page_detail">
            <HeadMeta {...meta} />

            <div className='text_title'>
                <h1>TÍNH NĂNG PHẦN MỀM QUẢN LÝ SUẤT ĂN KAFOOD</h1>
            </div>

            <Row className='m-0'>
                <Col md={{ span: 8, offset: 2 }}>
                    <div className="container bg-white">
                        <div className='p-lg-5 pt-3' >
                            <h3 className="fw-bold"> 1.	Quản lý suất ăn học sinh và nhân viên.</h3>
                            <p>
                                {/* -	Phần mềm báo ăn và cắt suất ăn học sinh chính xác, lưu lịch sử thời gian và người dùng. Chốt thời gian xin nghỉ của học sinh hằng ngày.
                                <br />     -	Tính được số lượng học sinh và nhân viên ăn hàng ngày tránh lãng phí thức ăn thừa, tính lại số tiền ăn trả lại cho phụ huynh chính xác khi học sinh nghỉ học.
                                <br />     -	Chấm cơm, chấm công nhân viên và học sinh chỉ trong 1 báo cáo. */}
                                -	Báo và cắt suất ăn học sinh, nhân viên chính xác, nhanh.<br />
                                -	Thống kê sỉ số học sinh, hằng ngày.<br />
                                -	Tính số tiền ăn trả lại cho phụ huynh khi học sinh nghỉ học.<br />
                                -	Lưu lịch sử báo cắt suất ăn của giáo viên.

                                <img src="../../../assets/user/images/product/detail/5_5.png" className="icon-small" alt="" width='100%' />
                            </p>

                            <h3 className="fw-bold">  2.	Thực đơn.</h3>
                            <p>
                                {/* -	Hỗ trợ phụ trách bán trú: lên thực đơn hàng tuần, hằng ngày nhanh chóng, in định lượng cho bếp theo món ăn và nhóm trẻ.
                                <br />     -	Xuất file làm sổ thực đơn tuần chi tiết.
                                <br />      -	Lên dự kiến phiếu đi chợ hằng ngày. */}
                                -	Lên thực đơn hàng tuần, hằng ngày nhanh chóng.<br />
                                -	In định lượng cho bếp theo món ăn và nhóm trẻ.<br />
                                -	Xuất file làm sổ thực đơn tuần chi tiết.<br />
                                -	Lên dự kiến phiếu đi chợ hằng ngày.

                                <img src="../../../assets/user/images/product/detail/2.png" className="icon-small" alt="" width='100%' />
                            </p>

                            <h3 className="fw-bold">  3.	Cân đối khẩu phần dinh dưỡng cho trẻ.</h3>
                            <p>
                                {/* -	Tính được năng lượng và thành phần dinh dưỡng (Protein, Gluxit, Lipid) theo buổi ăn và nhóm trẻ.
                                <br />   -	Cân đối khẩu phần ăn theo khuyến nghị viện dinh dưỡng, tính được thu chi chênh lệch tiền ăn.
                                <br />    -	Xuất file báo cáo hóa đơn đi chợ, kết quả dưỡng chất, và thiết lập dưỡng chất hằng ngày. */}
                                -	Xác định năng lượng và thành phần dinh dưỡng (Protein, Lipid,  Gluxit).<br />
                                -	Cân đối khẩu phần ăn theo khuyến nghị viện dinh dưỡng, tính thu chi chênh lệch tiền ăn.<br />
                                -	Xuất file báo cáo hóa đơn đi chợ, kết quả dưỡng chất, và thiết lập dưỡng chất hằng ngày.

                                <img src="../../../assets/user/images/product/detail/1_1.png" className="icon-small" alt="" width='100%' />
                            </p>

                            <h3 className="fw-bold">   4.	Quản lý nhân viên và phân quyền.</h3>
                            <p>
                                {/* -	Danh sách nhân viên, giáo viên theo chuẩn cơ sở dữ liệu quốc gia.
                                <br />     -	Phân quyền nhân viên chi tiết theo chức vụ được giao.
                                <br />      -	Chấm cơm và chấm công cho nhân viên.
                                <br />      -	Cấp tài khoản riêng cho nhân viên cắt suất ăn học sinh và xin nghỉ phép. */}
                                -	Danh sách nhân viên, giáo viên theo chuẩn cơ sở dữ liệu quốc gia.<br />
                                -	Phân quyền nhân viên chi tiết theo chức vụ được giao.<br />
                                -	Chấm cơm và chấm công cho nhân viên.<br />
                                -	Cấp tài khoản riêng cho nhân viên cắt suất ăn học sinh và xin nghỉ phép.

                                <img src="../../../assets/user/images/product/detail/8_8.png" className="icon-small" alt="" width='100%' />
                            </p>

                            <h3 className="fw-bold"> 5.	Quản lý danh  sách học sinh.</h3>
                            <p>
                                {/* -	Danh sách học sinh theo chuẩn cơ sở dữ liệu quốc gia.
                                <br />     -	Chuyển lớp, sắp lớp cho học sinh.
                                <br />     -	Xuất file danh sách học sinh có ngày sinh nhật trong tháng.
                                <br />     -	Xuất file báo cáo phổ cập giáo dục theo địa phương. */}
                                -	Danh sách học sinh theo chuẩn cơ sở dữ liêu quốc gia.<br />
                                -	Chuyển lớp, sắp lớp cho học sinh.<br />
                                -	Xuất file danh sách học sinh có ngày sinh nhật trong tháng.<br />
                                -	Xuất file báo cáo phổ cập giáo dục theo địa phương.

                                <img src="../../../assets/user/images/product/detail/9_9.png" className="icon-small" alt="" width='100%' />
                            </p>

                            <h3 className="fw-bold">  6.	Quản lý kho hàng và xuất nhập tồn.</h3>
                            <p>
                                {/* -	Phân loại nguyên vật liệu theo hàng chợ, hàng kho, vật tư.
                                <br />      -	Quản lý xuất nhập tồn theo lô và hạn sử dụng, cảnh báo hàng hết hạn, lưu lại lịch sử xuất nhập kho.
                                <br />      -	Có sẵn ngân hàng nguyên liệu đầy đủ thành phần năng lượng theo viện dinh dưỡng, bộ y tế. */}
                                -	Phân loại nguyên vật liệu theo hàng chợ, hàng kho, vật tư.<br />
                                -	Quản lý xuất nhập tồn theo lô và hạn sử dụng.<br />
                                -	Cảnh báo hàng hết hạn, lưu lại lịch sử xuất nhập kho.<br />
                                -	Có ngân hàng gần 600 nguyên liệu đầy đủ thành phần năng lượng theo viện dinh dưỡng, bộ y tế.

                                <img src="../../../assets/user/images/product/detail/6_6.png" className="icon-small" alt="" width='100%' />
                            </p>

                            <h3 className="fw-bold">  7.	Nhà cung cấp.</h3>
                            <p>
                                {/* -	Danh sách nhà cung cấp và lịch sử nhập hàng.
                                <br />  -	Kiểm tra biến động giá theo mặt hàng của nhà cung cấp đó và so sánh giá của các nhà cung cấp khác nhau.
                                <br /> -	Báo cáo chi phí mua hàng theo nhà cung cấp. */}
                                -	Danh sách nhà cung cấp và lịch sử nhập hàng.<br />
                                -	Kiểm tra giá cả lên xuống theo mặt hàng và so sánh giá của các nhà cung cấp khác nhau.<br />
                                -	Báo cáo chi phí mua hàng theo nhà cung cấp.

                                <img src="../../../assets/user/images/product/detail/7_7.png" className="icon-small" alt="" width='100%' />
                            </p>

                            <h3 className="fw-bold">     8.	Định lượng món ăn.</h3>
                            <p>
                                {/* -	Món ăn định lượng theo nhóm học sinh và nhân viên.
                                <br />   -	Ngân hàng có hàng nghìn món ăn cho trường tham khảo. */}
                                -	Món ăn định lượng theo nhóm học sinh và nhân viên.<br />
                                -	Ngân hàng có hàng nghìn món ăn cho trường tham khảo.

                                <img src="../../../assets/user/images/product/detail/4_4.png" className="icon-small" alt="" width='100%' />
                            </p>

                            <h3 className="fw-bold"> 9.	Biểu mẫu báo cáo theo chuẩn phòng sở.</h3>
                            <p>
                                {/* -	Sổ kiểm thực 3 bước.
                                <br />  -	Sổ lưu mẫu ngày.
                                <br />  -	Sổ tài chính công khai.
                                <br />  -	Sổ thực đơn tuần. */}
                                -	Sổ kiểm thực 3 bước.<br />
                                -	Sổ lưu mẫu ngày.<br />
                                -	Sổ tài chính công khai.<br />
                                -	Sổ thực đơn tuần.

                                <img src="../../../assets/user/images/product/detail/3_3.png" className="icon-small" alt="" width='100%' />
                            </p>

                            <h3 className="fw-bold">10.	 Quản lý thu chi tiền ăn.</h3>
                            <p>
                                {/* -	Báo cáo thu tiền ăn trong tháng.
                                <br />   -	Báo cáo chi phí tiền ăn và bán trú. */}
                                -	Báo cáo thu tiền ăn trong tháng.<br />
                                -	Báo cáo chi phí tiền ăn (hàng chợ, kho) và bán trú (hàng vật tư).

                                {/* <img src="../../../assets/user/images/product/detail/footer.png" className="icon-small" alt="" width='100%' /> */}
                            </p>

                        </div>
                       
                    </div>
                </Col>
            </Row>

        </div>
    );
}

export default Detail;