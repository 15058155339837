import axios from "axios";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Card } from "react-bootstrap";
import { useParams, NavLink,useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import momentFormat from "../../other/momentFormat";
// import DocumentMeta from 'react-document-meta';
import { Link } from 'react-router-dom';
import './blog.scss';
import HeadMeta from '../../other/HeadMeta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const BlogDetail = () => {
    const productSlug  = useLocation();
    useEffect(() => {
        if (post.topicId === '') {
            document.title = "Bảo hiểm VietinBank | Câu chiện bảo hiểm";
            window.scrollTo({ top: 0, behavior: 'smooth' });
            getPostBySlug();

        }
    });
    useEffect(() => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
        getPostBySlug();
    }, [productSlug]);
    const [post, setPost] = useState({
        topicId: '',
        topicSlug: '',
        topicNameVi: '',
        authId: '',
        authName: '',
        slug: '',
        keyword: '',
        image: '',
        titleVi: '',
        // titleEn: '',
        descVi: '',
        // descEn: '',
        contentVi: '',
        // contentEn: '',
        statusId: '',
        createdAt: '',
    });
    const [related, seRelated] = useState({})

    const viewStorerelatedPost = () => {
        if (related.length > 0) {
            return related.map((item, i) => {
                return (
                    <Col sm={3} xs={6} key={`postItem${i}`} className=''>
                        <Card >
                            <Card.Img variant="top" className='image-story' src={item.banner} />
                            <Card.Body>
                                <Card.Title className='limited-text-title'>
                                    <Link className='text-dark' to={`/cau-chuyen/${item.slug}`}>
                                        <span onClick={() => localStorage.setItem("id_bai-viet", item.id)}>
                                            {item.title}
                                        </span>

                                    </Link>
                                </Card.Title>
                                <Card.Text className='limited-text-3 text-muted '>
                                    {item.description}
                                </Card.Text>
                                {/* <Button variant="outline-primary" onClick={() => navigate()}> Xem chi tiết &#8594;</Button> */}
                            </Card.Body>
                        </Card>
                    </Col >
                )
            })
        }
    }
    const getPostBySlug = async () => {


        // if (productSlug !== '') {
        await axios.get(`/post/${localStorage.getItem('id_bai-viet')}`).then((res) => {
            if (res.data.errCode === 0) {
                let postData = res.data.post;

                setPost({
                    // topicId: postData.topicId,
                    // topicSlug: postData.topicSlug,
                    // topicNameVi: postData.topicNameVi,
                    // authId: postData.authId,
                    // authName: postData.authName,
                    slug: postData.slug,
                    keyword: postData.keywords,
                    image: postData.banner,
                    titleVi: postData.title,
                    descVi: postData.description,
                    contentVi: postData.content,
                    // statusId: postData.statusId,
                    // createdAt: postData.createdAt,
                });
                seRelated(postData.relatedPosts)

            } else {
                toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }


    const meta = {
        title: post.titleVi,
        description: post.descVi,
        keywords: post.keyword,
        og_image: '',
        fb_app_id: '',
    };

    return (
        <>
            <Breadcrumb className='py-2'>
                <Breadcrumb.Item href="/">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>   <Breadcrumb.Item href="/cau-chuyen">
                    Câu chuyện bảo hiểm
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{post.titleVi}</Breadcrumb.Item>
            </Breadcrumb>
            <div
                //   className='detail_bg' 
                id="page_detail_blog">
                {/* <Row className='m-0'>
                <Col md={{ span: 8, offset: 2 }}> */}
                <div
                    className=" bg-white"
                // container px-lg-5 pb-md-3 
                // bg-white"
                >


                    <h1 className="text-center">{post.titleVi}</h1>
                    {/* <i>{post.authName} {momentFormat(post.createdAt, "dddd, Do MMMM YYYY")}</i> */}

                    <p className="blog-desc text-muted">{post.descVi}</p>

                    <div className="blog-content" dangerouslySetInnerHTML={{ __html: post.contentVi }}></div>
                </div>
                {/* </Col>
            </Row > */}
                <div className="bg-white mt-2">
                    <div className='title-product'>
                        <h1>
                            Bài viết liên quan
                        </h1>
                        <div class="underline"></div>

                    </div>
                    <Row>
                        {viewStorerelatedPost()}
                    </Row>
                </div>

                <HeadMeta {...meta} />
            </div >
        </>

    );
}

export default BlogDetail;

