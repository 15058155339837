import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sildebar';
import { toast } from 'react-toastify';
import { Outlet, Navigate } from 'react-router-dom';
import Footer from './Footer';

import '../../assets/admin/style.css';
import '../../assets/admin/reponsive.css';

const MainLayout = () => {
    const sidenavToggle = () => {
        const sbNavFixed = document.getElementById('sb-nav-fixed_');

        let testClass = sbNavFixed.className;

        if (testClass === 'sb-nav-fixed_ sb-sidenav-toggled' && window.innerWidth < 992) {
            sbNavFixed.classList.remove('sb-sidenav-toggled');
        }
    }

    if (!localStorage.getItem('access_token')) {
        toast("Bạn chưa đăng nhập!", { type: "error" });
        return <Navigate to="/dang-nhap" />;
    }

    return (
        <div className='sb-nav-fixed_' id="sb-nav-fixed_">
            <Navbar />

            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <Sidebar />
                </div>

                <div id="layoutSidenav_content_" onClick={sidenavToggle}>
                    <Outlet />

                    <Footer />
                </div>
            </div>

        </div>
    );
}

export default MainLayout;
