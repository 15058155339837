import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { Col, Row, Card, Breadcrumb } from 'react-bootstrap';
import { Link, NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';

const PageTopicIndex = () => {
    const { topicSlug } = useParams();
    const [topicSlugCurent, setTopicSlugCurent] = useState('');

    useEffect(() => {
        if (topicSlug !== topicSlugCurent) {
            document.title = `VBI | Câu chuyện bảo hiểm`;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            getPostAll();
        }
    });

    const [posts, setPosts] = useState([]);
    const [topic, setTopic] = useState([]);

    const getPostAll = async () => {
        await axios.get(`/getPostsByTopics/${topicSlug}`).then((res) => {
            if (res.data.errCode === 0) {
                setPosts(res.data.posts[0].posts);
                setTopic(res.data.posts[0]);
                setTopicSlugCurent(topicSlug);

                document.title = `Kafood | ${res.data.posts[0].nameVi}`;
            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const viewPosts = () => {
        let output = [];

        if (posts.length > 0) {
            for (let i = 0; i < posts.length; i++) {
                output[i] = (<Col md={i * 2 / 3 % 3 === 0 ? 6 : 3} key={`postItem${posts[i].id}`}>
                    <Card >
                        <Card.Img variant="top" src={posts[i].image} />

                        <Card.Body>
                            <Card.Title>
                                <Link to={`/bai-viet/${posts[i].slug}`}>
                                    {posts[i].titleVi}
                                </Link>
                            </Card.Title>
                            <Card.Text>
                                {posts[i].descVi}
                            </Card.Text>

                            <div className='cart-reviews'>
                                <Card.Link href="#">
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                </Card.Link>
                                <Card.Link href="#">
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                </Card.Link>
                            </div>
                        </Card.Body>
                    </Card>
                </Col >);
            }
        } else {
            output = <h3>Hiện tại chưa có bài viết!</h3>;
        }

        return output;
    }

    return (
        <div className='detail_bg' id="page_blog">
            <div className='text_title'>
                <h1>{topic.nameVi}</h1>
            </div>
            <div className="container pt-3 ps-4 ">
                <Breadcrumb >
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/" }}>
                        Trang chủ
                    </Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/goc-chia-se" }}>
                        Góc chia sẻ
                    </Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{
                        to: `/chu-de/${topic.slug}`
                    }}>
                        {topic.nameVi}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="container p-lg-4 pb-md-0  mb-4">
                <Row >
                    {viewPosts()}
                </Row>
            </div>
        </div >
    )
}

export default PageTopicIndex;