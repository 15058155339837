import moment from 'moment';
// import 'moment-range';
// import 'moment-timezone';
import 'moment/locale/vi.js';
import "moment/min/locales.min";

moment.locale('vi');

const momentFormat = (dateString, format = '') => {
    // ####### use zones #####:
    // const newYork = moment.tz("2014-06-01 12:00", "America/New_York");
    // const losAngeles = newYork.clone().tz("America/Los_Angeles");
    // const london = newYork.clone().tz("Europe/London");

    // ####### if date is not string use conversion #######:
    // value.toLocaleDateString() +' '+ value.toLocaleTimeString();
    if (!dateString) {
        return '';
    }

    if (format) {
        return moment(dateString).format(format);
    } else {
        return moment(dateString);  // It will use default global format
    }
}

export default momentFormat;