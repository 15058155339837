import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const UseeEditModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
     
        getUser();
    }, [navigate]);

    const [user, setUser] = useState({
        fullName: '',
        image: '',
        phone: '',
        email: '',
        address: '',
        account: '',
        password: '',
        genderId: 'O',
        roleId: 'R1',
        statusId: 'S1',
        deleteId: 'D1',
    });

    const [errors, setErrors] = useState([]);
    const [allcodes, setAllcodes] = useState([]);
    const [isShowPassword, setIsShowPassword] = useState(true);

   
    const id = props.idEdit || '';

    const getUser = async () => {
        if (id !== '') {
            await axios.get(`/user/${id}`).then((res) => {
                if (res.data.errCode === 0) {
                    let userData = res.data.user;

                    setUser({
                        fullName: userData.fullname,
                        // image: userData.image,
                        phone: userData.phonenumber,
                        email: userData.email,
                        // address: userData.address,
                        account: userData.username,
                        password: '',
                        genderId: userData.gender,
                        roleId: userData.roleId,
                        statusId: userData.statusId,
                        deleteId: userData.deleteId,

                    });
                } else {
                    toast(res.data.message, { type: 'error' });
                }
            }).catch((error) => {
                // toast(error.message, { type: 'error' });
            });
        }
    }

    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        user[field] = value;
        setUser({ ...user });

        delete (errors[field]);
        setErrors({ ...errors });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            fullname: user.fullName,
           
            phonenumber: user.phone,
            email: user.email,
         
            username: user.account,
            password: user.password,
            gender: user.genderId,
            dateOfBirth:'1970-01-01'
          
        };

        await axios.put(`/user/update/${id}`, data).then((res) => {
            if (res.data.errCode === 1) {
                setErrors(res.data.errMessage);

                toast('Cập nhật thành viên thất bại.', { type: 'error' });
            } else {

                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();

                toast(res.data.errMessage, { type: 'success' });
            }
        }).catch((error) => {
         
        });
    }

    const optionGender = () => {
        if (allcodes.length > 0) {
            let genders = allcodes.filter((item, i) => item.key === 'GENDER');
            return genders.map((item, i) => {
                return <option key={`genderOption${i}`} value={item.type}>{item.valueVi}</option>
            })
        }
    }

    const optionStatus = () => {
        if (allcodes.length > 0) {
            let status = allcodes.filter((item, i) => item.key === 'STATUS');
            return status.map((item, i) => {
                return <option key={`statusOption${i}`} value={item.type}>{item.valueVi}</option>
            })
        }
    }

    const optionRole = () => {
        if (allcodes.length > 0) {
            let roles = allcodes.filter((item, i) => item.key === 'ROLE');
            return roles.map((item, i) => {
                return <option key={`roleOption${i}`} value={item.type}>{item.valueVi}</option>
            })
        }
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết thành viên</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit} autoComplete="off">
                    <Modal.Body>
                        <Row>
                            <Col md={6}>
                       
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={handleInput}
                                     name="fullName" 
                                     value={user.fullName} 
                                    className={errors?.fullName ? 'is-invalid' : ''} placeholder="Họ và tên" autoComplete="off" />
                                    <Form.Label>Họ và tên<i className="text-danger">*</i></Form.Label>
                                    <span className='text-danger'>{errors?.fullName ? errors?.fullName : ''}</span>
                                </Form.Floating>
                            </Col>

                            <Col md={6}>
                                <FloatingLabel label={<>Giới tính</>}>
                                    <Form.Select onChange={(e) => handleInput(e)} value={user.genderId} name="gender"
                                     >
                                        <option key={`genderOption1`} value={true}>Nam</option>
                                        <option key={`genderOption2`} value={false}>Nữ</option>

                                    </Form.Select>

                                    <span className='text-danger'>{errors?.genderId ? errors?.genderId : ''}</span>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={handleInput} name="email" value={user.email} className={errors?.email ? 'is-invalid' : ''} placeholder="Email" autoComplete='off' />
                                    <Form.Label>Email<i className="text-danger">*</i></Form.Label>
                                    <span className='text-danger'>{errors?.email ? errors?.email : ''}</span>
                                </Form.Floating>
                            </Col>

                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={handleInput} name="phone" value={user.phone} className={errors?.phone ? 'is-invalid' : ''} placeholder="Số điện thoại" />
                                    <Form.Label>Số điện thoại<i className="text-danger">*</i></Form.Label>
                                    <span className='text-danger'>{errors?.phone ? errors?.phone : ''}</span>
                                </Form.Floating>
                            </Col>
                        </Row>

                   

                        <Row>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={handleInput} name="account" value={user.account} className={errors?.account ? 'is-invalid' : ''} placeholder="Tài khoản" />
                                    <Form.Label>Tài khoản</Form.Label>
                                    <span className='text-danger'>{errors?.account ? errors?.account : ''}</span>
                                </Form.Floating>
                            </Col>

                            <Col md={6}>
                                <Form.Floating className="mb-3  input-group">
                                    <Form.Control
                                        onChange={handleInput}
                                        name='password'
                                        value={user.password}
                                        type={isShowPassword ? "password" : "text"}
                                        autoComplete="new-password"
                                        placeholder="Mật khẩu"
                                    />

                                    <InputGroup.Text className='btn-eye' onClick={() => setIsShowPassword(!isShowPassword)} style={{ width: '3rem', cursor: 'pointer' }} title={isShowPassword ? 'Hiện' : 'Ẩn'} >
                                        <FontAwesomeIcon icon={isShowPassword ? faEyeSlash : faEye} />
                                    </InputGroup.Text>
                                    <Form.Label >
                                        Mật khẩu
                                    </Form.Label>
                                    <span className='text-danger '>{errors?.password ? errors?.password : ''}</span>

                                </Form.Floating>
                            </Col>
                        </Row>

                  
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Cập nhật
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    );
}

export default UseeEditModal;