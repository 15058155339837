import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Row, Button, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import ModalSupport from '../home/ModalSupport'
import { toast } from "react-toastify";
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ProductDetail = () => {
  const [showModalSupport, setShowModalSupport] = useState(false);
const locationSlug=useLocation()

  useEffect(() => {
    document.title = "VBI | Sản phẩm";
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getCategorywithProduct()
    getProductDetail()
  }, []);
  const [product,setproduct]=useState({})
  const [categories, setcategories] = useState([])
  const getCategorywithProduct = () => {
    axios.get(`/category/products`).then((res) => {
      if (res.data.errCode === 0) {
        let data = res.data.categories;
        setcategories(data)
      }
    })
      .catch((error) => {
        toast('Lỗi', { type: 'error' });
      });
  }   

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      getProductDetail()
    }, [locationSlug]);

  const getProductDetail = () => {
    axios.get(`/product/${localStorage.getItem('id_product_detail')}`).then((res) => {
      if (res.data.errCode === 0) {
        let data = res.data.product;
        setproduct(data)
      }
    })
      .catch((error) => {
        toast('Lỗi', { type: 'error' });
      });
  }
  
  return (
    <div>
      {showModalSupport ?
        < ModalSupport show={showModalSupport} onHide={() => setShowModalSupport(false)} />
        : null
      }

      <Breadcrumb className='py-2'>
        <Breadcrumb.Item href="/">
          <FontAwesomeIcon icon={faHome} />

        </Breadcrumb.Item>
        <Breadcrumb.Item >Sản phẩm</Breadcrumb.Item>
        <Breadcrumb.Item active>{product.name}</Breadcrumb.Item>

      </Breadcrumb>

      <img
        src={product.banner1} alt='bannertop' className='image-bannertop-product'
      />

      <div className='name-product' >
        <div>
          {product.name}
        </div>
        <div>
          <Button onClick={() => setShowModalSupport(true)}>
            Tư vấn mua hàng
          </Button>

        </div>
      </div>

      <Row className='bg-white ms-me-0'>
        <Col md={9} className='content-product'>
          <div>
            <div className="blog-content" dangerouslySetInnerHTML={{ __html: product.description }}></div>
          </div>

          <Tabs
            defaultActiveKey="1"
            id="uncontrolled-tab-example"
            className="mb-3 pt-4"
          >
            <Tab eventKey="1" title="Thông tin sản phẩm">
              <div className="blog-content" dangerouslySetInnerHTML={{ __html: product.productInfo }}></div>
              <center>
                <Button onClick={() => setShowModalSupport(true)} className='mb-3'>
                  Tư vấn mua hàng
                </Button>

              </center>
            </Tab>
            <Tab eventKey="2" title="Đối tượng được bảo hiểm">
              <div className="blog-content" dangerouslySetInnerHTML={{ __html: product.insuranceObject }}></div>
              <center>
                <Button onClick={() => setShowModalSupport(true)} className='mb-3'>
                  Tư vấn mua hàng
                </Button>

              </center>
            </Tab>
            <Tab eventKey="3" title="Hướng dẫn bồi thường" >
              <div className="blog-content" dangerouslySetInnerHTML={{ __html: product.indemnityGuide }}></div>
              <center>
                <Button onClick={() => setShowModalSupport(true)} className='mb-3'>
                  Tư vấn mua hàng
                </Button>

              </center>
            </Tab>
            <Tab eventKey="4" title="Tài liệu" >
              <div className="blog-content" dangerouslySetInnerHTML={{ __html: product.document }}></div>
              <center>
                <Button onClick={() => setShowModalSupport(true)} className='mb-3'>
                  Tư vấn mua hàng
                </Button>

              </center>
            </Tab>
          </Tabs>
          <img
            src={product.banner2} alt='bannerend' className='image-bannertop-product mb-3'
          />
        </Col>
        <Col md={3} className='category-product'>
          {categories?.map(item => {
            return (
              <>
                <div className='title-product'>
                  <h2 key={`titleproduct${item}`}>
                    {item.name}
                  </h2>
                  <div class="underline"></div>
                </div>
                <ul class="category-list">
                  {item.products?.map(e => {
                    return (
                      <>
                        <li  key={`id_product_detail${e}`}
                        onClick={() =>

                          localStorage.setItem("id_product_detail", e.id)
                          }>
                          <Link className='text-dark' 
                            to={`/san-pham/${e.slug}`}>
                            <span onClick={() => 
                          
                              localStorage.setItem("id_product_detail", e.id)
                              }>
                              {e.name}
                            </span>

                          </Link>
                        </li>
                      </>
                    )
                  })}
                </ul>

              </>
            )
          })}

        </Col>
      </Row>
    </div>
  );
}

export default ProductDetail;