import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Breadcrumb } from 'react-bootstrap';
import SunEditor from 'suneditor-react';
import { Link } from "react-router-dom";
import 'suneditor/dist/css/suneditor.min.css'
function HomeAdmin() {
    // const navigate = useNavigate();

    const [value, setValue] = useState({})
    const [img_src, setImg_src] = useState('')
    useEffect(() => {
        document.title = "Thêm bài viết";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const onChange = (value) => {
        setValue(value)
        console.log(value)
        // this.setState({ value });
        // if (this.props.onChange) {
        //   this.props.onChange(
        //     value.toString('html')
        //   );
        // }
    };
    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setImg_src(URL.createObjectURL(img))

        }
    };
    return (
        <main className='container'>
            <Breadcrumb className="mb-2 mt-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Quản lý bài viết
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Thêm bài viết</Breadcrumb.Item>
            </Breadcrumb>
            Thêm bài viết
            <Row>
                <Col>
                    <Form.Floating className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Tiêu đề"
                            //   defaultValue={
                            //   }
                            onChange={(e) => {

                            }}
                        />
                        <Form.Label htmlFor="floatingInputClassName">
                            Tiêu đề<i className="text-danger">*</i>
                        </Form.Label>
                        <div className="invalid-feedback"></div>
                    </Form.Floating>
                </Col>
                <Col>
                    <Form.Floating className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Tiêu đề"
                            //   defaultValue={

                            //   }
                            onChange={(e) => {
                            }}
                        />
                        <Form.Label htmlFor="floatingInputClassName">
                            Slug<i className="text-danger">*</i>
                        </Form.Label>
                        <div className="invalid-feedback"></div>
                    </Form.Floating>
                </Col>

            </Row>
            <Row>
                <Col>
                    <Form.Floating className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Tiêu đề"
                            //   defaultValue={
                            //   }
                            onChange={(e) => {

                            }}
                        />
                        <Form.Label htmlFor="floatingInputClassName">
                            Chủ đề<i className="text-danger">*</i>
                        </Form.Label>
                        <div className="invalid-feedback"></div>
                    </Form.Floating>
                </Col>
                <Col>
                    <Form.Floating className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Tiêu đề"
                            //   defaultValue={

                            //   }
                            onChange={(e) => {
                            }}
                        />
                        <Form.Label htmlFor="floatingInputClassName">
                            Từ khóa<i className="text-danger">*</i>
                        </Form.Label>
                        <div className="invalid-feedback"></div>
                    </Form.Floating>
                </Col>

            </Row>


            <Form.Floating>
                <Form.Control
                    type='phone'
                    className='mb-3'
                    placeholder='Mô tả'
                >
                </Form.Control>

                <Form.Label htmlFor="floatingInputClassName">Mô tả</Form.Label>
            </Form.Floating>
            <div className='mb-3'>
                <img src={img_src} style={{ height: '100px' }} alt='' />
                <h1>Hình đại diện</h1>
                <input type="file" name="myImage"
                    onChange={onImageChange}
                >

                </input>
            </div>
            {/* <TextEditor
                           value={value}
                           onChange={()=> console.log(value)}
                        /> */}
            <SunEditor
                height='200px'
                value={value}
                plugin=''
                onChange={onChange}
                setOptions={{
                    buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize"],
                        ['paragraphStyle', 'blockquote'],
                        [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript"
                        ],
                        ["fontColor", "hiliteColor"],
                        ["align", "list", "lineHeight"],
                        ["outdent", "indent"],

                        ["table", "horizontalRule", "link", "image", "video"],
                        // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                        // ['imageGallery'], // You must add the "imageGalleryUrl".
                        ["fullScreen", "showBlocks", "codeView"],
                        ["preview", "print"],
                        ["removeFormat"]

                        // ['save', 'template'],
                        //   '/', Line break
                    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                    defaultTag: "div",
                    minHeight: "300px",
                    showPathLabel: false,

                    // font: sortedFontOptions
                }}


            />

            <center>
                <Button> Post</Button></center>
        </main>
    )
}

export default HomeAdmin;




