import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from "react-select";

const CompensateAddModal = (props) => {
    const navigate = useNavigate();
    // console.log(props)
    useEffect(() => {

        getProvince()

    }, [navigate]);

    const [user, setUser] = useState({
        name: '',
        phonenumber: '',
        address: '',
        wardId: 0,
        provinceId: 0,

        districtId: 0
    });

    const [errors, setErrors] = useState([]);
    const [allcodes, setAllcodes] = useState([]);
    const [isShowPassword, setIsShowPassword] = useState(true);
    const [province, setprovince] = useState([])
    const [provinceChoice, setprovinceChoice] = useState({ value: 0, label: '--Chọn tỉnh--' })
    const [district, setdistrict] = useState([])
    const [districtChoice, setdistrictChoice] = useState({ value: 0, label: '--Chọn huyện--' })
    const [ward, setward] = useState([])
    const [wardChoice, setwardChoice] = useState({ value: 0, label: '--Chọn xã/ phường--' })
    const getProvince = async () => {
        var arr = []
        arr.push(
            {
                label: '--Chọn tỉnh--',
                value: 0
            }
        )
        await axios.get(`/province/list`).then((res) => {
            if (res.data.errCode === 0) {
                // setprovince(res.data.provinces);
                res.data.provinces?.map(item => {
                    arr.push({
                        label: item.name,
                        value: item.id
                    })
                })
                setprovince(arr);

            } else {
            }
        }).catch((error) => {
        });
    }
    const getDistrict = (id) => {
        var arr = []
        arr.push(
            {
                label: '--Chọn huyện--',
                value: 0
            }
        )
        axios.get(`/district/list?provinceId=${id}`).then((res) => {
            if (res.data.errCode === 0) {
                res.data.districs?.map(item => {
                    arr.push({
                        label: item.name,
                        value: item.id
                    })
                })
                setdistrict(arr);

            } else {
            }
        }).catch((error) => {
        });
    }
    const getWard = (id_province, id_district) => {
        var arr = []
        arr.push(
            {
                label: '--Chọn xã/ phường--',
                value: 0
            }
        )
        axios.get(`/ward/list?provinceId=${id_province}&districtId=${id_district}`).then((res) => {
            if (res.data.errCode === 0) {
                // setprovince(res.data.provinces);
                res.data.wards?.map(item => {
                    arr.push({
                        label: item.name,
                        value: item.id
                    })
                })
                setward(arr);

            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }


    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        user[field] = value;
        setUser({ ...user });

        delete (errors[field]);
        setErrors({ ...errors });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: user.name.trim(),
            phonenumber: user.phonenumber.trim(),
            address: user.address,
            indemnityCategoryId:Number( props.id_category),
            wardId: wardChoice.value || 0,
            provinceId:provinceChoice.value || 0,
            districtId: districtChoice.value || 0,
        };

        await axios.post(`/indemnityAreaInfo/create`, data).then((res) => {
            if (res.data.errCode === 1) {

                setErrors(res.data.errMessage
                );

            } else {

                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();

                toast(res.data.errMessage, { type: 'success' });
            }
        }).catch((error) => {
            toast('Lỗi', { type: 'error' });
        });
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Thêm đơn vị  {props.id_category == 1 ? 'bệnh viện' : 'gara'} bồi thường</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit} autoComplete="off ">
                    <Modal.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => handleInput(e)} name="name"
                                        value={user.name}
                                      
                                        className={errors?.name?'err-input-form':''}
                                        placeholder="Họ và tên"
                                        required
                                    />
                                    <Form.Label>Tên đơn vị<i className="text-danger">*</i></Form.Label>
                                    <span className='err-input'>{errors?.name ? errors?.name : ''}</span>
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={(e) => handleInput(e)} name="phonenumber" value={user.phonenumber} required
                                         className={errors?.phonenumber?'err-input-form':''}
                                        placeholder="Số điện thoại" />
                                    <Form.Label>Số điện thoại<i className="text-danger">*</i></Form.Label>
                                    <span className='err-input'>
                                        {errors?.phonenumber}
                                    </span>
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    < Select
                                        options={province}
                                        defaultValue={provinceChoice}
                                        value={provinceChoice}
                                        name='province'
                                        onChange={(choice) => {
                                            setprovinceChoice(choice)
                                            setdistrictChoice({
                                                value: 0, label: '--Chọn huyện'
                                            })
                                            setwardChoice({
                                                value: 0, label: '--Chọn xã/ phường'
                                            })
                                            getDistrict(choice.value)
                                        }}
                                    >
                                    </Select >
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    < Select
                                        options={district}
                                        name='district'
                                        defaultValue={district[0]}
                                        value={districtChoice}
                                        onChange={(choice) => {
                                            setdistrictChoice(choice)
                                            getWard(provinceChoice.value, choice.value)
                                            setwardChoice({
                                                value: 0, label: '--Chọn xã/ phường'
                                            })
                                        }}
                                        isDisabled={provinceChoice.value == 0}

                                    >
                                    </Select >
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    < Select
                                        name='ward'
                                        options={ward}
                                        defaultValue={ward[0]}
                                        value={wardChoice}
                                        onChange={(choice) => {
                                            setwardChoice(choice)

                                        }}
                                        isDisabled={districtChoice.value == 0}

                                    >
                                    </Select >
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Control type="text" onChange={(e) => handleInput(e)} name="address" value={user.address}
                                    placeholder="Địa chỉ cụ thể"
                                    disabled={wardChoice.value == 0}
                                />
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default CompensateAddModal;