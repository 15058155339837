import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Button, Row, Col, Card, Container, Breadcrumb, Form } from "react-bootstrap";
import { toast } from 'react-toastify';
import axios from 'axios';
function SettingIndex() {
    const navigate = useNavigate();
    const [setting, setSetting] = useState(
        []
    )
    const [companyName, setcompanyName] = useState({})
    const [companyphonenumber, setcompanyphonenumber] = useState({})

    const [companyemail, setcompanyemail] = useState({})
    const [companyaddress, setcompanyaddress] = useState({})
    const [companyzaloNumber, setcompanyzaloNumber] = useState({})
    const [companyfacebookLink, setcompanyfacebookLink] = useState({})


    const company = {
        name: setting.name,
        phonenumber: setting.phonenumber,
        email: setting.email,
        address: setting.address,
        zaloNumber: setting.zaloNumber,
        facebookLink: setting.facebookLink,
    }

    useEffect(() => {
        document.title = "Cài đặt";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getSetting()
    }, [navigate]);
    const getSetting = async () => {
        await axios.get(`/company`).then((res) => {
            if (res.data.errCode === 0) {
                setSetting(res.data.company);
                setcompanyName(res.data.company.name)
                setcompanyaddress(res.data.company.address)
                setcompanyemail(res.data.company.email)
                setcompanyfacebookLink(res.data.company.facebookLink)
                setcompanyphonenumber(res.data.company.phonenumber)
                setcompanyzaloNumber(res.data.company.zaloNumber)
            } else {
                toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }
    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        company[field] = value;
        setSetting({ ...company });
        console.log(value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: companyName,
            phonenumber: companyphonenumber,
            email: companyemail,
            address: companyaddress,
            zaloNumber: companyzaloNumber,
            facebookLink: companyfacebookLink,

        };
        await axios.put(`/company/update`, data).then((res) => {
            if (res.data.errCode === 1) {
                toast('Cập nhật thất bại', { type: 'error' })
            } else {
                toast(res.data.errMessage, { type: 'success' });
            }
        }).catch((error) => {
            toast('Cập nhật thất bại', { type: 'error' })
        });
    }
    return (
        <div className='container'>
            <Breadcrumb className="pt-2 ps-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Tổng quan
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
            </Breadcrumb>
            <>
                {/* <center>
                    <div className='title-product text-center'>
                        Cài đặt thông tin chung
                        <div class="underline"></div>
                    </div>
                </center> */}
                <Form onSubmit={handleSubmit} autoComplete="off ">
                    <Row>
                        <Col md={6}>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    onChange={(e) => setcompanyName(e.target.value)}
                                    name="school_name"
                                    defaultValue={setting.name}
                                    // value={setting.name}
                                    type="text" id="school_name" placeholder="Tên trường học" required />
                                <label htmlFor="school_name">Tên công ty<i className="text-danger">*</i></label>
                            </Form.Floating>
                        </Col>
                        <Col md={8}>

                            <Form.Floating className="mb-3">
                                <Form.Control
                                    onChange={(e) => setcompanyaddress(e.target.value)}
                                    name="school_name"
                                    defaultValue={setting.address}
                                    type="text" id="school_name" placeholder="Tên trường học" required />
                                <label htmlFor="school_name">Địa chỉ<i className="text-danger">*</i></label>
                            </Form.Floating>
                        </Col>
                      
                      

                    </Row>
                    <Row>
                    <Col md={4}>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    onChange={(e) => setcompanyphonenumber(e.target.value)}
                                    name="school_name"
                                    defaultValue={setting.phonenumber}
                                    type="text" id="school_name" placeholder="Tên trường học" required />
                                <label htmlFor="school_name">Số điện thoại<i className="text-danger">*</i></label>
                            </Form.Floating>
                        </Col>
                        <Col md={4}>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    onChange={(e) => setcompanyemail(e.target.value)}
                                    defaultValue={setting.email}
                                    name="school_name"
                                    //  value={this.state.school.school_name} 
                                    type="text" id="school_name" placeholder="Tên trường học" />
                                <label htmlFor="school_name">Email<i className="text-danger">*</i></label>
                            </Form.Floating>
                        </Col>
                    </Row>
                    <Row>
                            <Col md={4}>

                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        onChange={(e) => setcompanyfacebookLink(e.target.value)}
                                        name="school_name"
                                        defaultValue={setting.facebookLink}
                                        type="text" id="school_name" placeholder="Tên trường học" />
                                    <label htmlFor="school_name">Link Facebook</label>
                                </Form.Floating>
                                </Col>
                                <Col md={4}>

                                    <Form.Floating className="mb-3">
                                        <Form.Control
                                            onChange={(e) => setcompanyzaloNumber(e.target.value)}
                                            name="school_name"
                                            defaultValue={setting.zaloNumber}
                                            type="text" id="school_name" placeholder="Tên trường học" />
                                        <label htmlFor="school_name">Link Zalo</label>
                                    </Form.Floating>
                                </Col>
                        
                        </Row>
                    {/* <center>
                        <div className='title-product text-center'>
                            Cài đặt banner trang chủ
                            <div class="underline"></div>
                        </div>
                    </center> */}

                    {/* <Row>
                        <Col md={12}>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    // onChange={this.handleInput}
                                    name="school_name"
                                    //  value={this.state.school.school_name} 
                                    type="text" id="school_name" placeholder="Tên trường học" />
                                <label htmlFor="school_name">Link banner<i className="text-danger">*</i></label>
                            </Form.Floating>
                        </Col>
                        <Col md={12}>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    // onChange={this.handleInput}
                                    name="school_name"
                                    //  value={this.state.school.school_name} 
                                    type="text" id="school_name" placeholder="Tên trường học" />
                                <label htmlFor="school_name">Link banner<i className="text-danger">*</i></label>
                            </Form.Floating>
                        </Col>
                        <Col md={12}>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    // onChange={this.handleInput}
                                    name="school_name"
                                    //  value={this.state.school.school_name} 
                                    type="text" id="school_name" placeholder="Tên trường học" />
                                <label htmlFor="school_name">Link banner<i className="text-danger">*</i></label>
                            </Form.Floating>
                        </Col>
                    </Row> */}
                    <Button
                        //  variant='warning'
                        style={{ float: 'center' }} type="submit">Cập nhật</Button>
                </Form>
            </>

        </div>

    )
}

export default SettingIndex;




