import { BrowserRouter, Routes, Route } from "react-router-dom";

import "sweetalert/dist/sweetalert.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MasterLayout from "../layouts/user/MasterLayout";
import Success from "../components/user/home/Success";
import HomeIndex from "../components/user/home/HomeIndex";
import About from "../components/user/home/About";
import Detail from "../components/user/detail/Detail";
import Cost from "../components/user/home/Cost";
import Login from "../components/user/auth/Login";
import CreatePost from "../components/admin/CreatePost";
import MainLayout from "../layouts/admin/MainLayout";
import HomeAdmin from "../components/admin/dashboard/HomeAdmin";
import UserIndex from "../components/admin/user/UserIndex";
import Error404 from "../components/other/Error404";
import TopicIndex from "../components/admin/topic/TopicIndex";
import PostsIndex from "../components/admin/posts/PostsIndex";
import BlogIndex from "../components/user/blog/BlogIndex";
import BlogDetail from "../components/user/blog/BlogDetail";
import PageTopicIndex from "../components/user/topic/PageTopicIndex";
import Test from "../components/user/home/Test";
import Compensate from "../components/user/compensate/Compensate";
import Product from "../components/admin/product/Product";
import ProductDetail from "../components/user/product/ProductDetail";
import Category from "../components/admin/product/Category";
import Story from "../components/user/blog/Story";
import SettingIndex from "../components/admin/settting/SettingIndex";
import CompensateAdmin from "../components/admin/compensate/Compensate";
import Zalo from "../components/user/home/Zalo";
export default function router() {
	return (
		<>
			<ToastContainer />

			<BrowserRouter>
				<Routes>
					<Route path="/" element={<MasterLayout />}>
						<Route index element={<HomeIndex />} />
						<Route path="zalo" element={<Zalo />} />
						<Route
							path="dang-ky-thanh-cong"
							element={<Success />}
						/>
						<Route path="gioi-thieu" element={<About />} />
						<Route
							path="san-pham/:slug"
							element={<ProductDetail />}
						/>
						<Route
							path="tinh-nang-phan-mem-quan-ly-suat-an-kafood"
							element={<Detail />}
						/>
						<Route path="test" element={<Test />} />
						<Route path="boi-thuong" element={<Compensate />} />
						<Route path="cau-chuyen" element={<Story />} />
						<Route path="bang-gia" element={<Cost />} />
						<Route path="dang-nhap" element={<Login />} />
						<Route path="goc-chia-se" element={<BlogIndex />} />
						<Route
							path="cau-chuyen/:slug"
							element={<BlogDetail />}
						/>

						<Route
							path="chu-de/:topicSlug"
							element={<PageTopicIndex />}
						/>
					</Route>

					<Route path="/admin" element={<MainLayout />}>
						<Route index element={<HomeAdmin />} />
						<Route path="create" element={<CreatePost />} />
						<Route path="ql-san-pham" element={<Product />} />
						<Route
							path="ql-danh-muc-san-pham"
							element={<Category />}
						/>
						<Route path="setting" element={<SettingIndex />} />

						<Route path="user" element={<UserIndex />} />
						<Route path="topic" element={<TopicIndex />} />
						<Route path="post" element={<PostsIndex />} />
						<Route
							path="compensate"
							element={<CompensateAdmin />}
						/>
					</Route>

					<Route path="*" element={<Error404 />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}
