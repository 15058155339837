import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormatData from '../../other/FormatData';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import {Row,Col} from 'react-bootstrap'

const CategoryEditModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        getTopic();
    }, [navigate]);

    const [slug, setSlug] = useState('');
    const [nameVi, setNameVi] = useState('');
    const [contentVi, setContentVi] = useState('');
    const [errors, setErrors] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const [selectedImagepost, setSelectedImagepost] = useState(null);
    const id = props.idEdit || '';

    const [isLoading, setIsLoading] = useState(false);


    const getTopic = async () => {
        if (id !== '') {
            setIsLoading(true);
            await axios.get(`/category/${id}`).then((res) => {
                if (res.data.errCode === 0) {
                    let topicData = res.data.category;
                    setSlug(topicData.slug);
                    setNameVi(topicData.name);
                    setSelectedImage(topicData.banner)
                    setIsLoading(false);
                } else {
                    toast(res.data.message, { type: 'error' });
                    setIsLoading(false);
                }
            }).catch((error) => {
                setIsLoading(false);
            });
        }
    }

    const handleClose = () => props.onHide();

    const handleInputNameVi = (e) => {
        let value = e.target.value;

        let convertToSlug = FormatData.convertToSlug(value);

        let dataErrors = { ...errors };
        delete (dataErrors?.slug);
        delete (dataErrors?.nameVi);

        setNameVi(value);
        setSlug(convertToSlug);
        setErrors(errors);
    }

    const handleSelectFile = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setSelectedImage(`data:image/jpeg;base64,${base64String}`);
                setSelectedImagepost(base64String)
            };
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setSelectedImage(`data:image/jpeg;base64,${base64String}`);
                setSelectedImagepost(base64String)
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        await swal({
            title: "Bạn muốn cập nhật danh mục sản phẩm?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then(async (ok) => {
            if (ok) {
                const data = {
                    image: selectedImagepost,
                    slug: slug,
                    name: nameVi,
                };

                await axios.put(`/category/update/${id}`, data).then((res) => {
                    if (res.data.errCode === 1) {
                        setErrors(res.data.errMessage);
                        toast('Cập nhật danh mục thất bại.', { type: 'error' });
                    } else {
                        const { onSubmitSuccess } = { ...props };
                        onSubmitSuccess();

                        toast(res.data.errMessage, { type: 'success' });
                    }
                }).catch((error) => {
                    toast('Lỗi', { type: 'error' });
                })
            }
        })
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết danh mục</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInputNameVi(e)} name="nameVi" value={nameVi} className={errors?.nameVi ? 'is-invalid' : ''} placeholder="Tên chủ đề" required />
                            <Form.Label>Tên chủ đề<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors?.nameVi ? errors?.nameVi : ''}</span>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" name="slug" value={slug} className={errors?.slug ? 'is-invalid' : ''} placeholder="Slug" readOnly />
                            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors?.slug ? errors?.slug : ''}</span>
                        </Form.Floating>
                        <div>
                            <label className="btn btn-primary me-3" style={{ fontSize: 12, cursor: "pointer" }} htmlFor="banner-top"> Thêm banner</label>
                            <input
                                type="file"
                                id="banner-top"
                                className="d-none"
                                accept='image/*'
                              
                                multiple={false}
                                onChange={(e) => handleSelectFile(e)}

                            />

                        </div>
                        <Row>
                            <Col>
                            {selectedImagepost? 
                                <Image className='mb-3 banner-admin' src={`data:image/jpeg;base64,${selectedImagepost}`}  thumbnail height={'60px'} />
                            :     <Image className='mb-3 banner-admin'   src={selectedImage} 
                            thumbnail height={'60px'} />
                            }
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Cập nhật
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default CategoryEditModal;