import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import ContactModal from "../../components/user/home/ContactModal";
import ModalSupport from "../../components/user/home/ModalSupport";
import Logo from "../../assets/user/images/logo.webp";
import { toast } from "react-toastify";
import axios from "axios";
const NavbarHome = (props) => {

	const [showContactModal, setShowContactModal] = useState(false);
	const [showModalSupport, setShowModalSupport] = useState(false);
	const [category, setcategory] = useState([]);
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		getSetting();
		getAllProduct();
	}, []);

	const [company, setcompany] = useState([]);

	const getSetting = async () => {
		await axios
			.get(`/company`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setcompany(res.data.company);
				} else {
					toast(res.data.message, { type: "error" });
				}
			})
			.catch((error) => {
				toast("Lỗi", { type: "error" });
			});
	};

	const getAllProduct = () => {
		axios
			.get(`/product/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setcategory(res.data.products);
				} else {
					toast(res.data.message, { type: "error" });
				}
			})
			.catch((error) => {
				toast("Lỗi", { type: "error" });
			});
	};

	const sidenavToggle = () => {
		const navbarSupportedContent = document.getElementById(
			"navbarSupportedContent",
		);

		let testClass = navbarSupportedContent.className;

		if (
			testClass === "navbar-collapse collapse show" &&
			window.innerWidth < 992
		) {
			navbarSupportedContent.classList.remove("show");
		}
	};

	return (
		<header id="header" className="top-head">
			<div className="hotline">
				<a href={`tel:  ${company.phonenumber}`}>
					<FontAwesomeIcon icon={faPhone} /> Liên hệ :{" "}
					{company.phonenumber}
				</a>
				<div className="icon_">
					<ul className="list-inline socials">
						<li className="list-inline-item px-1">
							<a href={company.facebookLink} target="_blank">
								<FontAwesomeIcon icon={faFacebookF} />
							</a>
						</li>

						<li className="list-inline-item px-1">
							<Nav.Link
								as={NavLink}
								to={"/zalo"}
								target="_blank"
								className="fw-bold"
							>
								Zalo
							</Nav.Link>
						</li>
					</ul>
				</div>
			</div>

			<Navbar collapseOnSelect expand="md" bg="light" variant="light">
				<Container fluid>
					<Link className="navbar-brand" to={"/"}>
						<img src={Logo} alt="logo_VBI" />
					</Link>
					{/* <button className="button-28 px-3" type="button" onClick={() => setShowContactModal(true)}>Đăng ký dùng thử</button> */}

					<Navbar.Toggle aria-controls="navbarSupportedContent" />

					<Navbar.Collapse id="navbarSupportedContent">
						<Nav className="ms-auto">
							<Nav.Link
								as={NavLink}
								to={"/"}
								onClick={sidenavToggle}
							>
								Trang chủ
							</Nav.Link>
							<Nav.Link
								as={NavLink}
								to={"/gioi-thieu"}
								onClick={sidenavToggle}
							>
								Giới thiệu
							</Nav.Link>
							<NavDropdown
								title="Sản phẩm"
								id="collasible-nav-dropdown"
							>
								{category?.map((item) => {
									return (
										<NavDropdown.Item
											as={NavLink}
											to={`/san-pham/${item.slug}`}
											onClick={() => {
												sidenavToggle();
												localStorage.setItem(
													"id_product_detail",
													item.id,
												);
											}}
										>
											<span
												onClick={() =>
													localStorage.setItem(
														"id_product_detail",
														item.id,
													)
												}
											>
												{item.name}
											</span>
										</NavDropdown.Item>
									);
								})}
							</NavDropdown>
							<Nav.Link
								as={NavLink}
								to={"/boi-thuong"}
								onClick={sidenavToggle}
								// onClick={() => setShowModalSupport(true)}
							>
								Hướng dẫn bồi thường
							</Nav.Link>
							<Nav.Link
								as={NavLink}
								to={"/cau-chuyen"}
								onClick={sidenavToggle}
							>
								Câu chuyện
							</Nav.Link>
							{/* <NavDropdown title="Câu chuyện bảo hiểm" id="collasible-nav-dropdown">
                                <NavDropdown.Item as={NavLink} to={'/cau-chuyen'} onClick={sidenavToggle}>Tất cả</NavDropdown.Item>
                              
                                {topicItem()}
                            </NavDropdown> */}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>

			{showContactModal ? (
				<ContactModal
					show={showContactModal}
					onHide={() => setShowContactModal(false)}
				/>
			) : null}
			{showModalSupport ? (
				<ModalSupport
					show={showModalSupport}
					onHide={() => setShowModalSupport(false)}
				/>
			) : null}
		</header>
	);
};

export default NavbarHome;
