import React, { useEffect,useState } from 'react';
import { Row, Col, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Link ,NavLink} from 'react-router-dom';
import axios from 'axios';

function Footer() {
    const [category, setcategory] = useState([])

    useEffect(() => {
     
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getSetting()
        getAllProduct()
    }, []);
    const [company,setcompany]=useState([])
    const getSetting = async () => {
        await axios.get(`/company`).then((res) => {
            if (res.data.errCode === 0) {
                setcompany(res.data.company)
            } else {
                toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            toast('Lỗi', { type: 'error' });
        });
    }
    const getAllProduct = () => {
        axios.get(`/product/list`).then((res) => {
            if (res.data.errCode === 0) {
                setcategory(res.data.products)
            } else {
                toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            toast('Lỗi', { type: 'error' });
        });
    }
    return (
        <footer className='mt-auto footer_home'>
            <Row className=" footer-link-box clearfix">
                <Col md={4} className="left-f-box">
                    <h4 className="fw-bold">{company.name}</h4>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faHome} />&nbsp;
                         {company.address}
                        </li>
                        <li><FontAwesomeIcon icon={faPhone} />&nbsp; <Link to={`tel:  ${company.phonenumber}`}> {company.phonenumber}</Link></li>
                        <li><FontAwesomeIcon icon={faEnvelope} />&nbsp; {company.email}</li>
                    </ul>
                </Col>

                <Col md={4} className="left-f-box">
                    <h4 className="fw-bold">HƯỚNG DẪN KHÁCH HÀNG</h4>
                    <ul>
                        <li><Link to="/" >Trang chủ</Link></li>
                        <li><Link to="/gioi-thieu">Giới thiệu</Link></li>
                        <li>
                        <NavDropdown title="Sản phẩm" id="collasible-nav-dropdown">
                                {category?.map(item => {
                                    return (
                                        <NavDropdown.Item as={NavLink} to={`/san-pham/${item.slug}`}

                                            onClick=
                                            {() => {
                                                // sidenavToggle()
                                                localStorage.setItem("id_product_detail", item.id)
                                            }

                                            }>
                                            <span onClick={() => localStorage.setItem("id_product_detail", item.id)}>
                                                {item.name}
                                            </span>

                                        </NavDropdown.Item>
                                    )
                                })}

                            </NavDropdown>
                        </li>
                        <li><Link to="boi-thuong">Hướng dẫn bồi thường</Link></li>
                        <li><Link to="cau-chuyen" >Câu chuyện </Link></li>
                    </ul>
                </Col>

                <Col md={4} className="left-f-box" >
                    <h4 className="fw-bold"><FontAwesomeIcon icon={faLocationDot} /> GOOGLE MAP</h4>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62858.575657858666!2d105.71596471684228!3d10.044809096950502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a08820f43c3fc5%3A0xd6634125bcdf0d49!2zVGVjaGNvbWJhbmsgQ-G6p24gVGjGoQ!5e0!3m2!1svi!2s!4v1693277657938!5m2!1svi!2s"
                        width="100%" height="200" style={{ border: '0' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Col>
            </Row>


            <div className="copyright">
                <p className='text-center'>  Design by <Link to='https://thietkeweb.katec.vn/' target='_blank'> Katec.</Link></p>
            </div>
        </footer>
    );
}

export default Footer;