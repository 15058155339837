import zaloImage from "../../../assets/user/images/VBI_ZALO.jpg";
import zaloImageMobile from "../../../assets/user/images/VBI_ZALO_Mobile.jpg";

import "./Zalo.css";
const Zalo = () => {
	return (
		<div className="d-flex justify-content-center">
			<img src={zaloImage} className="zalo-image my-4" />

			<img src={zaloImageMobile} className="zalo-image-mobile my-4" />
		</div>
	);
};

export default Zalo;
