import React, { useEffect } from 'react';
import { Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap';
// import {}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import icon2 from '../../../assets/user/images/about/img_khachhang_tn.svg'
import icon3 from '../../../assets/user/images/about/img_trainghiem_kb.svg'
import icon4 from '../../../assets/user/images/about/img_mang_luoi.svg'
import icon5 from '../../../assets/user/images/about/img1.svg'
import icon6 from '../../../assets/user/images/about/sang_tao.svg'
import icon7 from '../../../assets/user/images/about/img3.svg'


import icon8 from '../../../assets/user/images/about/img5.svg'

import icon9 from '../../../assets/user/images/about/img2.svg'
import icon10 from '../../../assets/user/images/about/img1.webp'
import icon12 from '../../../assets/user/images/about/img3.webp'

import icon11 from '../../../assets/user/images/about/sang_tao.svg'
import icon13 from '../../../assets/user/images/about/img_tienphong_bh_so.svg'





const About = () => {
  useEffect(() => {
    document.title = "VBI | Giới thiệu";
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  return (
    <div >

      <Breadcrumb className='py-2'>
        <Breadcrumb.Item href="/">
          <FontAwesomeIcon icon={faHome} />

        </Breadcrumb.Item>
        {/* <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
           Library
         </Breadcrumb.Item> */}
        <Breadcrumb.Item active>Giới thiệu</Breadcrumb.Item>
      </Breadcrumb>
      <div className='bg-white px-2'>
        <div className='about-sesion1'>

          <div className='text-about'>
            Bảo toàn giá trị cuộc sống
          </div>

        </div>
        <Tabs
          defaultActiveKey="1"
          id="uncontrolled-tab-example"
          className="mb-3 py-4 tab-about"
        >
          <Tab eventKey="1" title="Về VBI">
            <Row className='mb-2'>
              <Col md={6} style={{ textAlign: 'justify', lineHeight: '1.7rem' }}>
                <div style={{ textTransform: 'uppercase', fontWeight: '600', fontSize: '1.5rem' }}>
                  Tổng công ty cổ phần Bảo hiểm
                </div>
                <p>
                  Ngân hàng TMCP Công Thương Việt Nam
                  Bảo hiểm VietinBank (VBI) là công ty bảo hiểm phi nhân thọ năng động và có tốc độ tăng trưởng nhanh nhất trên thị trường trong 5 năm trở lại đây ở Việt Nam.
                </p>


                VBI tiền thân là Công ty TNHH MTV Bảo hiểm Ngân hàng TMCP Công thương VN trực thuộc VietinBank – là ngân hàng nằm trong TOP 3 có quy mô lớn nhất Việt Nam. Thành lập năm 2008 với sứ mệnh bảo toàn giá trị cuộc sống, VBI luôn nỗ lực cải tiến sản phẩm dịch vụ để mang lại những trải nghiệm giá trị, khác biệt cho khách hàng.
              </Col>
              <Col md={6}>
                <iframe width="100%" height="300" src="https://www.youtube.com/embed/z_6WsKEsvKI?si=uT22Sy4lvtizxr5y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </Col>

            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={2}>
                    <img src={icon13} alt='icon' />
                  </Col>
                  <Col md={10}>
                    <p style={{ fontSize: '1.3rem' }}>
                      Tiên phong bảo hiểm số
                    </p>
                    <p>
                      Nhờ tiên phong cải tiến, số hóa hàng trăm quy trình nghiệp vụ, VBI giúp khách hàng tiếp cận dịch vụ bảo hiểm một cách đơn giản, nhanh chóng chỉ bằng vài thao tác. Khách hàng có thể mua, được cấp đơn và phê duyệt phương án bồi thường… hoàn toàn online chỉ trong vài phút.
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <Row>
                  <Col md={2}>   <img src={icon2} alt='icon' />
                  </Col>
                  <Col md={10}>
                    <p style={{ fontSize: '1.3rem' }}>
                      Trải nghiệm khách hàng xuất sắc
                    </p>
                    <p>
                      Với phương châm “Khách hàng là trọng tâm”, VBI tự hào được các chuyên gia và cộng đồng đánh giá là có trải nghiệm dịch vụ xuất sắc, được hàng triệu khách hàng tin cậy lựa chọn.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={2}>   <img src={icon3} alt='icon' />
                  </Col>
                  <Col md={10}>
                    <p style={{ fontSize: '1.3rem' }}>
                      Trải nghiệm là khác biệt
                    </p>
                    <p>
                      Không giống như hầu hết các công ty Bảo hiểm Phi nhân thọ khác trên thị trường, VBI đã tìm hướng đi riêng với việc lấy trải nghiệm khách hàng làm trung tâm trong mọi hành động. Chính điều đó đã giúp VBI vượt qua mọi đối thủ và ghi dấu ấn mạnh mẽ trong lòng khách hàng.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={2}>
                    <img src={icon4} alt='icon' />
                  </Col>
                  <Col md={10}>
                    <p style={{ fontSize: '1.3rem' }}>
                      Mạng lưới rộng khắp
                    </p>
                    <p>
                      Không giống như hầu hết các công ty Bảo hiểm Phi nhân thọ khác trên thị trường, VBI đã tìm hướng đi riêng với việc lấy trải nghiệm khách hàng làm trung tâm trong mọi hành động. Chính điều đó đã giúp VBI vượt qua mọi đối thủ và ghi dấu ấn mạnh mẽ trong lòng khách hàng.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <center>
              <div className='title-product'>
                <h1>
                  Giá trị cốt lỗi
                </h1>

                <div class="underline"></div>
              </div>
              <Row style={{ justifyContent: 'center' }}>
                <Col md={4}>
                  <div>
                    <img src={icon5} alt='icon' className='img-about' />
                    <div>
                      <p style={{ fontSize: '1.3rem' }}>
                        Khách hàng
                      </p>
                      <p>
                        Lấy nhu cầu của khách hàng là mục tiêu phục vụ, luôn lắng nghe, phụng sự và đồng hành cùng khách hàng trên mọi hành trình bảo vệ
                      </p>
                    </div>
                  </div>

                </Col>
                <Col md={4}>
                  <div>
                    <img src={icon6} alt='icon' className='img-about' />
                    <div>
                      <p style={{ fontSize: '1.3rem' }}>
                        Sáng tạo
                      </p>
                      <p>
                        Liên tục cải tiến, đổi mới để tạo ra những giá trị tốt nhất cho hệ thống, khách hàng, trở thành doanh nghiệp bảo hiểm hàng đầu nhờ sự sáng tạo và khác biệt  </p>
                    </div>
                  </div>

                </Col>
                <Col md={4}>
                  <div>
                    <img src={icon7} alt='icon' className='img-about' />
                    <div>
                      <p style={{ fontSize: '1.3rem' }}>
                        Tận tâm
                      </p>
                      <p>
                        Luôn làm việc với nỗ lực cao nhất, dành trọn tâm huyết để hoàn thành công việc với kết quả tốt nhất, phục vụ khách hàng bằng sự chân thành, tận tụy, hết mình</p>
                    </div>
                  </div>

                </Col>
                <Col md={4}>
                  <div>
                    <img src={icon8} alt='icon' className='img-about' />
                    <div>
                      <p style={{ fontSize: '1.3rem' }}>
                        Chính trực
                      </p>
                      <p>
                        Luôn nhất quán trong suy nghĩ và hành động, đảm bảo sự tuân thủ, kỷ luật, kỷ cương, trung thực, minh bạch và giữ vững đạo đức nghề nghiệp
                      </p>
                    </div>
                  </div>

                </Col>
                <Col md={4}>
                  <div>
                    <img src={icon9} alt='icon' className='img-about' />
                    <div>
                      <p style={{ fontSize: '1.3rem' }}>
                        Hợp tác
                      </p>
                      <p>
                        Đề cao tinh thần hợp tác, chia sẻ, trong quan hệ đối tác, khách hàng, hướng tới mục tiêu chung, hài hòa lợi ích các bên vì sự phát triển vững mạnh    </p>
                    </div>
                  </div>

                </Col>
              </Row>

            </center>
            <center>
              <div className='title-product'>
                <h1>
                  Tầm nhìn - Mục tiêu - Sứ mệnh
                </h1>

                <div class="underline"></div>
              </div>
              <Row>
                <Col md={4}>
                  <div>
                    <img src={icon10} alt='icon' className='img-about' />
                    <div>
                      <p style={{ fontSize: '1.3rem' }}>
                        Tầm nhìn
                      </p>
                      <p>
                        Trở thành doanh nghiệp bảo hiểm hàng đầu nhờ sự sáng tạo và khác biệt, mang trong mình giá trị nhân văn sâu sắc</p>
                    </div>
                  </div>

                </Col>
                <Col md={4}>
                  <div>
                    <img src={icon11} alt='icon' className='img-about' />
                    <div>
                      <p style={{ fontSize: '1.3rem' }}>
                        Mục tiêu
                      </p>
                      <p>
                        Bảo toàn giá trị cuộc sống</p>
                    </div>
                  </div>

                </Col>
                <Col md={4}>
                  <div>
                    <img src={icon12} alt='icon' className='img-about' />
                    <div>
                      <p style={{ fontSize: '1.3rem' }}>
                        Sứ mệnh
                      </p>
                      <p>
                        Là điểm tựa vững chắc cho mọi gia đình, doanh nghiệp trước những khó khăn, rủi ro, bảo toàn cuộc sống, vì một tương lai tốt đẹp hơn</p>
                    </div>
                  </div>

                </Col>
              </Row>
            </center>
          </Tab>
      
          <Tab eventKey="4" title="Giải thưởng" >
            {/* <div className="blog-content" dangerouslySetInnerHTML={{ __html: dataEx[0].contentDoc }}></div> */}
          </Tab>
        </Tabs>
      </div>

    </div>



  );
}

export default About;