import React, { useEffect } from 'react';
import { Table, Col, Container, Row } from 'react-bootstrap';
const Cost = () => {
    useEffect(() => {
        document.title = "Kafood | Bảng giá";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const data_ = [
        {
            parent: "Quản lý suất ăn học sinh và nhân viên",
            id: 0,
            src: "assets/user/images/product/mobile/suatan.png",
            check1: 1,
            check2: 1,
            check3: 1

        },
        {
            parent: "Thực đơn",
            src: "assets/user/images/product/mobile/thucdon.png",
            id: 1,
            check1: 1,
            check2: 1,
            check3: 1

        },
        {
            parent: "Cân đối khẩu phần dinh dưỡng", id: 2,
            src: "assets/user/images/product/mobile/candoi.png",
            check1: 1,
            check2: 1,
            check3: 1
        },
        {
            parent: "Quản lý nhân viên và phân quyền", id: 3,
            src: "assets/user/images/product/mobile/nhanvien.png",
            check1: 1,
            check2: 1,
            check3: 1

        },
        {
            parent: "Quản lý danh sách học sinh", id: 4,
            src: "assets/user/images/product/mobile/danhsach.png",
            check1: 1,
            check2: 1,
            check3: 1
        },

        {
            parent: "Quản lý kho hàng và xuất nhập tồn", id: 5,
            src: "assets/user/images/product/mobile/kho.png",
            check1: 1,
            check2: 1,
            check3: 1
        },
        {
            parent: "Quản lý nhà cung cấp", id: 6,
            src: "assets/user/images/product/mobile/nhacungcap.png",
            check1: 1,
            check2: 1,
            check3: 1
        },
        {
            parent: "Định lượng món ăn", id: 7,
            src: "assets/user/images/product/mobile/dinhluong.png",
            check1: 1,
            check2: 1,
            check3: 1
        },
        {
            parent: "Biểu mẫu báo cáo", id: 8,
            src: "assets/user/images/product/mobile/bieumau.png",
            check1: 1,
            check2: 1,
            check3: 1
        },
        {
            parent: "Quản lý thu chi tiền ăn", id: 9,
            src: "assets/user/images/product/mobile/baocao.png",
            check1: 1,
            check2: 1,
            check3: 1
        }, {
            parent: 'Giới hạn số học sinh', id: 10,
            check1: ' Học sinh < 100',
            check2: ' Học sinh 100 - 500',
            check3: 'Không giới hạn'
        },

        {
            parent: 'Giới hạn tài khoản nhân viên', id: 11,
            check1: 'Nhân viên < 10',
            check2: 'Nhân viên < 50',
            check3: 'Không giới hạn'
        }

    ]
    return (
        <Container>
            <div className='text_title'>
                <h1>BẢNG GIÁ PHẦN MỀM KAFOOD</h1>
            </div>

            <Row className="row justify-content-center">
                <Col md={8} xs={12}>
                    <Table bordered hover className='tableCost'    >
                        <thead>
                            <tr>
                                <th>  Cơ bản     </th>
                                <th>  Cao cấp   </th>
                                <th>  Chuyên nghiệp     </th>
                            </tr>
                            <tr style={{backgroundColor:'#1f96d9'}}>
                                <th>
                                    150.000đ/tháng
                                </th>
                                <th>
                                    300.000đ/tháng
                                </th>
                                <th>
                                    600.000đ/tháng
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data_?.map((item, index) => {
                                return (item.id > 9 ?
                                    <tr key={`itemPrice${index}`}>
                                        <td>
                                            {item.check1}
                                        </td>
                                        <td>
                                            {item.check2}
                                        </td>
                                        <td>
                                            {item.check3}
                                        </td>
                                    </tr>
                                    : null)
                            })}
                        </tbody>
                    </Table>
                </Col>

            </Row>
        </Container>
    );
}

export default Cost;