import React from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'

const TextEditor = (props) => {

  // The toolbarConfig object allows you to specify custom buttons, reorder buttons and to add custom css classes.
  // Supported inline styles: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Inline-Styles.md
  // Supported block types: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Custom-Block-Render.md#draft-default-block-render-map
  // const toolbarConfig = {
  //   // Optionally specify the groups to display (displayed in the order listed).
  //   display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  //   INLINE_STYLE_BUTTONS: [
  //     { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
  //     { label: 'Italic', style: 'ITALIC' },
  //     { label: 'Underline', style: 'UNDERLINE' }
  //   ],
  //   BLOCK_TYPE_DROPDOWN: [
  //     { label: 'Normal', style: 'unstyled' },
  //     { label: 'Heading Large', style: 'header-one' },
  //     { label: 'Heading Medium', style: 'header-two' },
  //     { label: 'Heading Small', style: 'header-three' }
  //   ],
  //   BLOCK_TYPE_BUTTONS: [
  //     { label: 'UL', style: 'unordered-list-item' },
  //     { label: 'OL', style: 'ordered-list-item' }
  //   ]
  // };

  const option = {
    buttonList: buttonList.complex,
    code: 'en',
    toolbar: {
      default: 'Default',
      save: 'Save',
      font: 'Font',
      formats: 'Formats',
      fontSize: 'Size',
      bold: 'Bold',
      underline: 'Underline',
      italic: 'Italic',
      strike: 'Strike',
      subscript: 'Subscript',
      superscript: 'Superscript',
      removeFormat: 'Remove Format',
      fontColor: 'Font Color',
      hiliteColor: 'Highlight Color',
      indent: 'Indent',
      outdent: 'Outdent',
      align: 'Align',
      alignLeft: 'Align left',
      alignRight: 'Align right',
      alignCenter: 'Align center',
      alignJustify: 'Align justify',
      list: 'List',
      orderList: 'Ordered list',
      unorderList: 'Unordered list',
      horizontalRule: 'Horizontal line',
      hr_solid: 'Solid',
      hr_dotted: 'Dotted',
      hr_dashed: 'Dashed',
      table: 'Table',
      link: 'Link',
      math: 'Math',
      image: 'Image',
      video: 'Video',
      audio: 'Audio',
      fullScreen: 'Full screen',
      showBlocks: 'Show blocks',
      codeView: 'Code view',
      undo: 'Undo',
      redo: 'Redo',
      preview: 'Preview',
      print: 'print',
      tag_p: 'Paragraph',
      tag_div: 'Normal (DIV)',
      tag_h: 'Header',
      tag_blockquote: 'Quote',
      tag_pre: 'Code',
      template: 'Template',
      lineHeight: 'Line height',
      paragraphStyle: 'Paragraph style',
      textStyle: 'Text style',
      imageGallery: 'Image gallery',
      mention: 'Mention'
    },
    dialogBox: {
      linkBox: {
        title: 'Insert Link',
        url: 'URL to link',
        text: 'Text to display',
        newWindowCheck: 'Open in new window',
        downloadLinkCheck: 'Download link',
        bookmark: 'Bookmark'
      },
      mathBox: {
        title: 'Math',
        inputLabel: 'Mathematical Notation',
        fontSizeLabel: 'Font Size',
        previewLabel: 'Preview'
      },
      imageBox: {
        title: 'Insert image',
        file: 'Select from files',
        url: 'Image URL',
        altText: 'Alternative text'
      },
      videoBox: {
        title: 'Insert Video',
        file: 'Select from files',
        url: 'Media embed URL, YouTube/Vimeo'
      },
      audioBox: {
        title: 'Insert Audio',
        file: 'Select from files',
        url: 'Audio URL'
      },
      browser: {
        tags: 'Tags',
        search: 'Search',
      },
      caption: 'Insert description',
      close: 'Close',
      submitButton: 'Submit',
      revertButton: 'Revert',
      proportion: 'Constrain proportions',
      basic: 'Basic',
      left: 'Left',
      right: 'Right',
      center: 'Center',
      width: 'Width',
      height: 'Height',
      size: 'Size',
      ratio: 'Ratio'
    },
    controller: {
      edit: 'Edit',
      unlink: 'Unlink',
      remove: 'Remove',
      insertRowAbove: 'Insert row above',
      insertRowBelow: 'Insert row below',
      deleteRow: 'Delete row',
      insertColumnBefore: 'Insert column before',
      insertColumnAfter: 'Insert column after',
      deleteColumn: 'Delete column',
      fixedColumnWidth: 'Fixed column width',
      resize100: 'Resize 100%',
      resize75: 'Resize 75%',
      resize50: 'Resize 50%',
      resize25: 'Resize 25%',
      autoSize: 'Auto size',
      mirrorHorizontal: 'Mirror, Horizontal',
      mirrorVertical: 'Mirror, Vertical',
      rotateLeft: 'Rotate left',
      rotateRight: 'Rotate right',
      maxSize: 'Max size',
      minSize: 'Min size',
      tableHeader: 'Table header',
      mergeCells: 'Merge cells',
      splitCells: 'Split Cells',
      HorizontalSplit: 'Horizontal split',
      VerticalSplit: 'Vertical split'
    },
    menu: {
      spaced: 'Spaced',
      bordered: 'Bordered',
      neon: 'Neon',
      translucent: 'Translucent',
      shadow: 'Shadow',
      code: 'Code'
    }
  }

  // const handleKeyDown = (event) => {
  //   // console.log('event.target>>>>>', event.target); //Get the keydown event
  //   const { onKeyDown } = { ...props };
  //   onKeyDown(event.target);
  // }

  const handleChange = (content) => {
    const { onChange } = { ...props };
    onChange(content);
  }

  return (<>
    {/* <RichTextEditor
        toolbarConfig={toolbarConfig}
        value={this.state.value}
        onChange={this.onChange}
      /> */}

    <SunEditor
    height='fit-content'
      // setAllPlugins={true} 
      // setAllPlugins={false}
      setOptions={option}
      // onKeyDown={handleKeyDown}
      onChange={handleChange}
      // value={this.state.value}
      // onChange={this.onChange}
      setContents={props.setContents}

      placeholder={props.placeholder || ''}
    />
  </>
  )
}
export default TextEditor;