import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Row, Tabs, Tab, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import ModalSupport from '../home/ModalSupport';
import DataTables from '../../other/DataTables';
import Select from "react-select";

import axios from 'axios';
const Compensate = () => {
    useEffect(() => {
        document.title = "VBI | Hướng dẫn bồi thường";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getBaiViet()
        getCompensate(keyTab,0,0,0)
        getProvince()

    }, []);

    const [filterText, setfilterText] = useState("")
    const [keyTab, setKeyTab] = useState(1)

    const [showModalSupport, setShowModalSupport] = useState(false);
    const [contentVi, setcontentVi] = useState({})

    const [province, setprovince] = useState([])
    const [provinceChoice, setprovinceChoice] = useState({ value: 0, label: '--Chọn tỉnh--' })

    const [district, setdistrict] = useState([])
    const [districtChoice, setdistrictChoice] = useState({ value: 0, label: '--Chọn huyện--' })

    const [ward, setward] = useState([])
    const [wardChoice, setwardChoice] = useState({ value: 0, label: '--Chọn xã/ phường--' })

    const [indemnityAreaInfos, setindemnityAreaInfos] = useState([])

    const toSlug = (str) => {
        if (str !== 'undefined') {
            str = str.toLowerCase(); // Chuyển hết sang chữ thường       
            str = str
                .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
                .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
            str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
            str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
        }

        return str;
    }
    const getProvince = async () => {
        var arr = []
        arr.push(
            {
                label: '--Chọn tỉnh--',
                value: 0
            }
        )
        await axios.get(`/province/list`).then((res) => {
            if (res.data.errCode === 0) {
                // setprovince(res.data.provinces);
                res.data.provinces?.map(item => {
                    arr.push({
                        label: item.name,
                        value: item.id
                    })
                })
                setprovince(arr);

            } else {
            }
        }).catch((error) => {
        });
    }
    const getDistrict = (id) => {
        var arr = []
        arr.push(
            {
                label: '--Chọn huyện--',
                value: 0
            }
        )
        axios.get(`/district/list?provinceId=${id}`).then((res) => {
            if (res.data.errCode === 0) {
                res.data.districs?.map(item => {
                    arr.push({
                        label: item.name,
                        value: item.id
                    })
                })
                setdistrict(arr);

            } else {
            }
        }).catch((error) => {
        });
    }
    const getWard = (id_province, id_district) => {
        var arr = []
        arr.push(
            {
                label: '--Chọn xã/ phường--',
                value: 0
            }
        )
        axios.get(`/ward/list?provinceId=${id_province}&districtId=${id_district}`).then((res) => {
            if (res.data.errCode === 0) {
                // setprovince(res.data.provinces);
                res.data.wards?.map(item => {
                    arr.push({
                        label: item.name,
                        value: item.id
                    })
                })
                setward(arr);

            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }
    const getCompensate = (id_category,wardChoiceid,districtChoiceid,provinceChoiceid) => {

        axios.get(`indemnityAreaInfo/list?indemnityCategory=${id_category}&ward=${wardChoiceid}&district=${districtChoiceid}&province=${provinceChoiceid}`).then((res) => {
            if (res.data.errCode === 0) {
                setindemnityAreaInfos(res.data.indemnityAreaInfos);

            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }
    const getBaiViet = async () => {
        await axios.get(`/post/list?limit=1&page=1&topicId=2`).then((res) => {
            if (res.data.errCode === 0) {
                axios.get(`/post/${res.data.posts[0].id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        // setbaiviet(res.data.post);
                        setcontentVi(res.data.post.content)
                    } else {
                    }
                }).catch((error) => {
                });

            } else {
            }
        }).catch((error) => {
        });
    }

    const showTopicAll = () => {

        const columns = [
            {
                name: "Tên bệnh viện",
                selector: (row) => row.nameVi,
                sortable: true,
                wrap: true
            },
            {
                name: "Số điện thoại",
                selector: (row) => row.phone,
                sortable: true,
                wrap: true
            }, {
                name: "Địa chỉ",
                selector: (row) => row.number,
                sortable: true,
                wrap: true
            }, {
                name: "Xã/ phường",
                selector: (row) => row.ward,
                sortable: true, 
                wrap: true
            }, {
                name: "Huyện",
                selector: (row) => row.district,
                sortable: true,
                wrap: true
            }, {
                name: "Tỉnh",
                selector: (row) => row.province,
                sortable: true,
                wrap: true
            },
        ];

        const data = [];

        if (indemnityAreaInfos.length > 0) {
            indemnityAreaInfos.map((item, i) => {
                data.push({
                    id: item.id,
                    nameVi: item.name,
                    phone: item.phonenumber,
                    ward: item.ward,
                    district: item.district,
                    province: item.province,
                    number: item.address
                    // updatedAt: item.updatedAt,
                });
                return item;
            });
        }
        const filteredItems = data.filter((item) => {

            if (
                (toSlug(item.nameVi)).includes(toSlug(filterText))
            ) {

                return item;

            }
            return false
        }


        );
        return (<>
            <Row className='p-2'>

                <Col md={2}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            value={filterText}
                            aria-label="Search"
                            onChange={(e) => {
                                setfilterText(e.target.value)
                            }}
                        />

                    </Form>
                </Col>
                <Col md={3} style={{ zIndex: 4 }}>
                    <Select
                        options={province}
                        defaultValue={provinceChoice}
                        value={provinceChoice}
                        onChange={(choice) => {
                            setprovinceChoice(choice)
                            setdistrictChoice({
                                value: 0, label: '--Chọn huyện'
                            })
                            setwardChoice({
                                value: 0, label: '--Chọn xã/ phường'
                            })
                            getDistrict(choice.value)
                        }}
                    />
                </Col>
                <Col md={3} style={{ zIndex: 3 }}>
                    < Select
                        options={district}
                        defaultValue={district[0]}
                        value={districtChoice}
                        onChange={(choice) => {
                            setdistrictChoice(choice)
                            getWard(provinceChoice.value, choice.value)
                            setwardChoice({
                                value: 0, label: '--Chọn xã/ phường'
                            })
                        }}
                        isDisabled={provinceChoice.value == 0}
                    />
                </Col>
                <Col md={3} style={{ zIndex: 2 }}>
                    <Select
                        options={ward}
                        defaultValue={ward[0]}
                        value={wardChoice}
                        onChange={(choice) => {
                            setwardChoice(choice)
                        }}
                        isDisabled={districtChoice.value == 0}
                    />
                </Col>

                <Col md={1}>
                    <Button variant="outline-secondary" onClick={() => getCompensate(1,wardChoice.value,districtChoice.value,provinceChoice.value)} ><FontAwesomeIcon icon={faSearch} /></Button>
                </Col>

            </Row>

            <DataTables data={filteredItems} columns={columns} />
        </>
        )
    }

    const showGarageAll = () => {
    
        const columns = [
            {
                name: "Tên gara",
                selector: (row) => row.nameVi,
                sortable: true,
                wrap: true
            },
            {
                name: "Số điện thoại",
                selector: (row) => row.phone,
                sortable: true,
                wrap: true
            }, {
                name: "Địa chỉ",
                selector: (row) => row.number,
                sortable: true,
                wrap: true

            }, {
                name: "Xã/ phường",
                selector: (row) => row.ward,
                sortable: true,
                wrap: true
            }, {
                name: "Huyện",
                selector: (row) => row.district,
                sortable: true,
                wrap: true
            }, {
                name: "Tỉnh",
                selector: (row) => row.province,
                sortable: true,
                wrap: true
            },

        ];

        const data = [];

        if (indemnityAreaInfos.length > 0) {
            indemnityAreaInfos.map((item, i) => {
                data.push({
                    id: item.id,
                    nameVi: item.name,
                    phone: item.phonenumber,
                    ward: item.ward,
                    district: item.district,
                    province: item.province,
                    number: item.address
                });
                return item;
            });
        }
        const filteredItems = data.filter((item) => {

            if (
                (toSlug(item.nameVi)).includes(toSlug(filterText))
            ) {

                return item;

            }
            return false
        }


        );
        return (<>
            <Row className='p-2'>

                <Col md={2}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            value={filterText}
                            aria-label="Search"
                            onChange={(e) => {
                                setfilterText(e.target.value)
                            }}
                        />

                    </Form>
                </Col>
                <Col md={3} style={{ zIndex: 4 }}>
                    < Select
                        options={province}
                        defaultValue={provinceChoice}
                        value={provinceChoice}
                        onChange={(choice) => {
                            setprovinceChoice(choice)
                            setdistrictChoice({
                                value: 0, label: '--Chọn huyện'
                            })
                            setwardChoice({
                                value: 0, label: '--Chọn xã/ phường'
                            })
                            getDistrict(choice.value)
                        }}
                    />
                </Col>
                <Col md={3} style={{ zIndex: 3 }}>
                    <Select
                        options={district}
                        defaultValue={district[0]}
                        value={districtChoice}
                        onChange={(choice) => {
                            setdistrictChoice(choice)
                            getWard(provinceChoice.value, choice.value)
                            setwardChoice({
                                value: 0, label: '--Chọn xã/ phường'
                            })
                        }}
                        isDisabled={provinceChoice.value == 0}
                    />
                </Col>
                <Col md={3} style={{ zIndex: 2 }}>
                    <Select
                        options={ward}
                        defaultValue={ward[0]}
                        value={wardChoice}
                        onChange={(choice) => {
                            setwardChoice(choice)
                        }}
                        isDisabled={districtChoice.value == 0}
                    />
                </Col>
                <Col md={1}>
                    <Button variant="outline-secondary" onClick={() => getCompensate(2,wardChoice.value,districtChoice.value,provinceChoice.value)} ><FontAwesomeIcon icon={faSearch} /></Button>
                </Col>
            </Row>

            <DataTables data={filteredItems} columns={columns} />
        </>
        )
    }
    return (
        <div >
            {showModalSupport ?
                < ModalSupport show={showModalSupport} onHide={() => setShowModalSupport(false)} />
                : null
            }
            <Breadcrumb className='pt-2'>
                <Breadcrumb.Item href="/">
                    <FontAwesomeIcon icon={faHome} />

                </Breadcrumb.Item>
                <Breadcrumb.Item active>Hướng dẫn bồi thường</Breadcrumb.Item>
            </Breadcrumb>
            <Button onClick={() => setShowModalSupport(true)} style={{ float: 'right', marginTop: '1rem' }}>
                Liên hệ tư vấn
            </Button>
            <Tabs
                defaultActiveKey="0"
                id="uncontrolled-tab-example"
                className=" py-2 tab-about"
                onSelect={(k) => {
                    getCompensate(k,0,0,0)
                    setKeyTab(k)
                    setfilterText('')
                    setdistrictChoice({
                        value: 0, label: '--Chọn huyện'
                    })
                    setwardChoice({
                        value: 0, label: '--Chọn xã/ phường'
                    })
                    setprovinceChoice({
                        value: 0, label: '--Chọn tỉnh'
                    })
                }}
            >
                <Tab eventKey="0" title="Hướng dẫn chung">
                    <div className="blog-content bg-white p-2  " dangerouslySetInnerHTML={{ __html: contentVi }}></div>
                </Tab>
                <Tab eventKey="1" title="Hệ thống bệnh viện bảo lãnh viện phí" className=' bg-white' >
                    <div style={{ minHeight: '200px ' }}>
                        {showTopicAll()}
                    </div>
                </Tab>
                <Tab eventKey="2" title="Hệ thống gara " className=' bg-white'>
                    <div style={{ minHeight: '200px ' }}>
                        {showGarageAll()}
                    </div>
                </Tab>
            </Tabs>
        </div>



    );
}

export default Compensate;